import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SideNav from '../components/SideNav';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import { Base_URL } from '../constants';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Modal, Switch, Tab, Tabs, TextField, Typography } from '@mui/material';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import swal from 'sweetalert';
import RealTimeSideNav from '../components/RealTimeSideNav';
import HistoricalSideNav from '../components/HistoricalSideNav';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Notifications = () => {
    const [cookies] = useCookies(['access_token']);
    const [data, setData] = useState({});
    const [switches, setSwitches] = useState({});
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [openModal, setOpenModal] = useState(false);
    const [openManageModal, setOpenManageModal] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [tabValue, setTabValue] = useState(0);
    const [formData, setFormData] = useState({});
    const [checkboxData, setCheckboxData] = useState([]);
    const [allInclusive, setAllInclusive] = useState();
    
    useEffect(() => {
        const fetchData = async () => {
            showLoader();
            try {
                const response = await axios.get(Base_URL + '/alerts/connectors', {
                    headers: {
                        'Cookie': cookies.access_token
                    }
                });
                hideLoader();
                setData(response.data.data);
                
                const initialSwitchesState = {};
                Object.keys(response.data.data).forEach(key => {
                    initialSwitchesState[key] = false;
                });
                setSwitches(initialSwitchesState);
            } catch (error) {
                hideLoader();
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSwitchChange = async (key) => {
        showLoader();
        setSwitches(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
        
        try {  
            await axios.post(Base_URL + '/alerts/connectors/status', {
                connector_name: key,
                is_active: !data[key].is_active
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Cookie': `access_token=${cookies.access_token}`
                }
            });
            hideLoader();
            window.location.reload();
        } catch (error) {
            hideLoader();
            console.error('Error updating switch status:', error);
        }
    };

    const keyMapping = {
        service_now: 'ServiceNow',
        zoho_desk: 'Zoho Desk',
        whatsapp: 'Whats App',
        voice_call: 'Voice Call'
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const [handleKey, setHandleKey] = useState('')
    const handleAddNotification = async (key, configJson) => {
        setOpenModal(true);
        setInputValues(configJson);
        setHandleKey(key);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setInputValues({});
    };

    const handleCloseManageModal = () => {
        setOpenManageModal(false);
    };

    const handleSubmit = async () => {
        showLoader();
        try {
            let payload;
            if (handleKey === 'email') {
                const emailArray = Object.values(inputValues).filter(email => email !== '');
                payload = {
                    connector_name: handleKey,
                    config_json: {
                        email_id: emailArray
                    }
                };
            } else {
                payload = {
                    connector_name: handleKey,
                    config_json: inputValues
                };
            }
    
            const response = await axios.post(Base_URL + '/alerts/connectors', payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Cookie': `access_token=${cookies.access_token}`
                }
            });
            hideLoader();
            handleCloseModal();
        } catch (error) {
            hideLoader();
            console.error('Error adding notification:', error);
        }
    };

    const [service, setService] = useState('');

    const fetchConfigData = async (serviceName) => {
        setService(serviceName)
        showLoader();
        try {
            const response = await axios.post(Base_URL + '/alerts/connectors/details', {
                connector_name: serviceName
            });
            hideLoader();
            return response.data.data[serviceName];
        } catch (error) {
            hideLoader();
            console.error('Error fetching config data:', error);
            return null;
        }
    };

    const handleManageNotification = async (serviceName) => {
        const configData = await fetchConfigData(serviceName);
        if (configData) {
            setFormData(configData.config_json);
            setCheckboxData(configData.list_wow_alertid);
            setAllInclusive(configData.all_inclusive);
            setTabValue(0);
            setOpenManageModal(true);
        } else {
            console.error('Failed to fetch config data.');
        }
    };

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
    
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    };

    const handleFormChange = (e, key) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            [key]: value,
        });
    };   

    const handlePhoneChange = (phone, country, e, key) => {
        const countryCode = `+${country.dialCode}`;
        const currentPhoneNumber = formData[key];
        const currentCountryCode = currentPhoneNumber?.slice(0, countryCode.length);
    
        const newPhoneValue = currentCountryCode !== countryCode ? countryCode : phone;
    
        const newFormData = { ...formData, [key]: newPhoneValue };
        setFormData(newFormData);
    };    
    
    const handleUpdateConfig = async () => {
        showLoader();
        try {
            let payload;
            if (service === 'email') {
                const emailArray = Object.values(formData).filter(email => email.trim() !== '');
                payload = {
                    connector_name: service,
                    config_json: { email_id: emailArray }
                };
            } else {
                payload = {
                    connector_name: service,
                    config_json: formData
                };
            }
    
            const response = await axios.patch(Base_URL + '/alerts/connectors', payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Cookie': cookies.access_token
                }
            });
            hideLoader();
            swal({
                icon: 'success',
                title: 'Success!',
                text: 'Config updated successfully!',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result) {
                  window.location.reload();
                }
            });
        } catch (error) {
            hideLoader();
        }
    };
      

    const handleCheckboxChange = (index) => {
        if (allInclusive) {
            setCheckboxData(prevData => {
                if (prevData.includes((index + 1).toString())) {
                    return prevData.filter(item => item !== (index + 1).toString());
                } else {
                    return prevData;
                }
            });
        } else {
            const updatedCheckboxData = checkboxData.includes((index + 1).toString())
                ? checkboxData.filter(item => item !== (index + 1).toString())
                : [...checkboxData, (index + 1).toString()];
            setCheckboxData(updatedCheckboxData);
        }
    };
    
    const handleIncludeAll = () => {
        setAllInclusive(true);
        setCheckboxData(Array.from(Array(6).keys()).map(index => (index + 1).toString()));
    };
    
    const handleExcludeAll = () => {
        setAllInclusive(false);
        setCheckboxData([]);
    };    

    const handleSubmitNotification = async () => {
        showLoader();
        const requestData = {
            connector_name: service,
            all_inclusive: allInclusive,
            list_wow_alertid: checkboxData
        };
        
        try {
            const response = await axios.patch(Base_URL + '/alerts/connectors/update_to_alert', requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Cookie': cookies.access_token
                }
            });
            hideLoader();
            swal({
                icon: 'success',
                title: 'Success!',
                text: 'Notification updated successfully!',
                confirmButtonText: 'OK'
            }).then(() => {
                setOpenManageModal(false);
                window.location.reload();
            });
        } catch (error) {
            hideLoader();
            console.error('Error updating notification:', error);
            swal({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to update notification. Please try again later.',
                confirmButtonText: 'OK'
            });
        }
    };

    const openIntegrationGuide = () => {
        const pdfUrl = 'Wowizer_tpm_teams_integration (1).pdf';
        window.open(pdfUrl, '_blank');
    };
      
    const currentNav = localStorage.getItem('currentNav');
    return (
        <>
                <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '20px',
                    minHeight: '81vh',
                }}
            >
                {Object.keys(data)
                    .sort((a, b) => {
                        const isActiveA = 'is_active' in data[a];
                        const isActiveB = 'is_active' in data[b];
                        const isComingSoonA = a !== 'email' && a !== 'teams' && a !== 'voice_call';
                        const isComingSoonB = b !== 'email' && b !== 'teams' && b !== 'voice_call';

                        if (isActiveA !== isActiveB) return isActiveB - isActiveA;
                        if (isComingSoonA !== isComingSoonB) return isComingSoonA - isComingSoonB;
                        return 0;
                    })
                    .map((key) => {
                        const service = data[key];
                        const hasConfigJson = 'config_json' in service;

                        const logoUrl = `${process.env.PUBLIC_URL}/${key}.png`;

                        const displayName = keyMapping[key] || capitalizeFirstLetter(key);

                        if (!data[key].hasOwnProperty('is_active')) {
                            return (
                                <Card key={key} sx={{ margin: '10px', width: '250px', height: '200px' }}>
                                    <CardContent>
                                        <Box display="flex" alignItems="center">
                                            <img
                                                src={logoUrl}
                                                alt={`${key} logo`}
                                                style={{ width: 40, height: 40, marginRight: 10 }}
                                            />
                                            <Typography variant="h6">{displayName}</Typography>
                                        </Box>
                                        <Box marginTop={9}>
                                            <Button
                                                variant="contained"
                                                onClick={() => handleAddNotification(key, data[key].properties)}
                                                disabled={key !== 'email' && key !== 'teams' && key !== 'voice_call'}
                                            >
                                                {key === 'email' || key === 'teams' || key === 'voice_call'
                                                    ? 'Add Notification'
                                                    : 'Coming Soon'}
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            );
                        }

                        return (
                            <Card key={key} sx={{ margin: '10px', width: '250px' }}>
                                <CardContent>
                                    <Box display="flex" alignItems="center">
                                        <img
                                            src={logoUrl}
                                            alt={`${key} logo`}
                                            style={{ width: 40, height: 40, marginRight: 10 }}
                                        />
                                        <Typography variant="h6">{displayName}</Typography>
                                    </Box>
                                    <Box marginTop={2} display="flex" alignItems="center">
                                        <Switch
                                            checked={data[key].is_active}
                                            onChange={() => handleSwitchChange(key)}
                                            color="primary"
                                        />
                                    </Box>
                                    <Box marginTop={2}>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleManageNotification(key)}
                                            disabled={key !== 'email' && key !== 'teams' && key !== 'voice_call'}
                                        >
                                            {key === 'email' || key === 'teams' || key === 'voice_call'
                                                ? hasConfigJson
                                                    ? 'Manage Notification'
                                                    : 'Manage Notification'
                                                : 'Coming Soon'}
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        );
                    })}
            </div>

            {loader}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        minWidth: 400,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Add Notification
                    </Typography>
                    {Object.keys(inputValues).map((inputKey) => (
                        <TextField
                            key={inputKey}
                            label={inputKey}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            onChange={(e) => setInputValues(prevState => ({ ...prevState, [inputKey]: e.target.value }))}
                        />
                    ))}
                    <Box sx={{ textAlign: 'right' }}>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                        <Button variant="outlined" color="primary" onClick={handleCloseModal} sx={{ ml: 2 }}>Cancel</Button>
                    </Box>
                </Box>
            </Modal>
            <Modal open={openManageModal} onClose={() => setOpenManageModal(false)} style={{ marginTop: '10%' }}>
                    <Box sx={{ p: 3, backgroundColor: 'white', width: 800, margin: 'auto', marginTop: '1%', maxHeight: '90vh', overflowY: 'auto' }}>
                        <Tabs
                            value={tabValue}
                            onChange={(event, newValue) => setTabValue(newValue)}
                            variant="fullWidth"
                            aria-label="Tabs for managing notifications"
                        >
                            <Tab label="Update Config" />
                            <Tab label="Manage Notification" />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            { service === "voice_call" ? 
                            <>
                            <Box mt={2}>
                                {Object.entries(formData).map(([key, value]) => (
                                    service === 'voice_call' ? (
                                        <>
                                        <ReactPhoneInput
                                            key={key}
                                            country={'us'}
                                            value={formData[key]}
                                            onChange={(phone, country, e) => handlePhoneChange(phone, country, e, key)}
                                            inputStyle={{
                                                width: '100%',
                                                fontSize: '16px',
                                                borderRadius: '4px',
                                            }}
                                        />
                                        <br />
                                        <Button variant='contained' onClick={handleUpdateConfig}>Update Config</Button>
                                        </>
                                    ) : (
                                        <>
                                        <TextField
                                            key={key}
                                            fullWidth
                                            label={key}
                                            variant="outlined"
                                            value={formData[key]}
                                            onChange={(e) => handleFormChange(e, key)}
                                            margin="normal"
                                        />
                                        <Button variant='contained' onClick={handleUpdateConfig}>Update Config</Button>
                                        </>
                                    )
                                ))}
                            </Box>
                            </>
                            :
                            <>
                            <Box mt={2}>
                                {Object.entries(formData).map(([key, value]) => (
                                    <TextField
                                        key={key}
                                        fullWidth
                                        label={key}
                                        variant="outlined"
                                        value={formData[key]}
                                        onChange={(e) => handleFormChange(e, key)}
                                        margin="normal"
                                    />

                                ))}
                            </Box>
                            <Button variant='contained' onClick={handleUpdateConfig}>Update Config</Button>
                            </>
                            }
                            {formData.hasOwnProperty("webhook_url") && (
                                <Button variant="outlined" onClick={() => openIntegrationGuide()} style={{ marginLeft: '2%' }}>How to Integrate?</Button>
                            )}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Button variant="contained" color="primary" onClick={handleIncludeAll}>Include All</Button>
                            <Button variant="contained" color="primary" onClick={handleExcludeAll} style={{ marginLeft: '1%' }}>Exclude All</Button>
                            <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                                {Array.from(Array(5).keys()).map((index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={checkboxData.includes((index + 1).toString())}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                        }
                                        label={`Severity ${index + 1}`}
                                        style={{ marginTop: '2%' }}
                                    />
                                ))}
                            </FormGroup>
                            <br />
                            <Button variant="contained" color="primary" onClick={handleSubmitNotification}>Submit</Button>
                        </TabPanel>
                    </Box>
            </Modal>
        </>
    );
};

export default Notifications;