import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import RealTimeSideNav from '../components/RealTimeSideNav';
import HistoricalSideNav from '../components/HistoricalSideNav';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ReloadSchedule from './ReloadSchedule';
import AlertingConfig from './AlertingConfig';
import Notifications from './Notifications';

const AlertsTabs = () => {
  const currentNav = localStorage.getItem('currentNav');
  const [activeTab, setActiveTab] = useState(0);

  const handleButtonClick = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      <SessionExpiredAlert />
      {currentNav === 'Real Time' ? <RealTimeSideNav /> : <HistoricalSideNav />}
      <Header />
      <div className="content-wrapper">
        <br />
        <Box 
          sx={{ 
            width: '100%', 
            mt: -1, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}
        >
          <Button
            onClick={() => handleButtonClick(0)}
            variant={activeTab === 0 ? 'contained' : 'outlined'}
            sx={{
              textTransform: 'capitalize',
              borderRadius: 0, 
              margin: 0,
              padding: '5px 20px',
              minWidth: '150px',
              marginRight: 0,
              '&:not(:last-child)': {
                marginRight: '0px',
              },
            }}
          >
            Alerts Configuration
          </Button>
          <Button
            onClick={() => handleButtonClick(1)}
            variant={activeTab === 1 ? 'contained' : 'outlined'}
            sx={{
              textTransform: 'capitalize',
              borderRadius: 0, 
              margin: 0,
              padding: '5px 20px',
              minWidth: '150px',
              marginRight: 0,
              '&:not(:last-child)': {
                marginRight: '0px',
              },
            }}
          >
            Reload Driven Alerts
          </Button>
          <Button
            onClick={() => handleButtonClick(2)}
            variant={activeTab === 2 ? 'contained' : 'outlined'}
            sx={{
              textTransform: 'capitalize',
              borderRadius: 0, 
              margin: 0,
              padding: '5px 20px',
              minWidth: '150px',
              marginRight: 0,
              '&:not(:last-child)': {
                marginRight: '0px',
              },
            }}
          >
            Notification Channels
          </Button>
        </Box>

        {activeTab === 0 && <AlertingConfig />}
        {activeTab === 1 && <ReloadSchedule />}
        {activeTab === 2 && <Notifications />}
      </div>
      <Footer />
    </>
  );
};

export default AlertsTabs;