import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Modal, Box, Typography, Button, IconButton, InputLabel, FormControl, Select, MenuItem, FormControlLabel, Checkbox, TextField, RadioGroup, Radio } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import Header from '../components/Header';
import Footer from '../components/Footer';
import RealTimeSideNav from '../components/RealTimeSideNav';
import HistoricalSideNav from '../components/HistoricalSideNav';
import { Base_URL } from '../constants';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import swal from 'sweetalert';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import TimezoneSelect from 'react-timezone-select';

const ReloadSchedule = () => {
    const [reloadSchedules, setReloadSchedules] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    const currentNav = localStorage.getItem('currentNav');

    const fetchReloadSchedules = async () => {
        showLoader();
        try {
            const config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: Base_URL + '/anomaly/reload_schedule',
                headers: {}
            };
            const response = await axios.request(config);
            setReloadSchedules(response.data.reload_schedules);
        } catch (error) {
            console.error('Error fetching reload schedules:', error);
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        fetchReloadSchedules();
    }, []);

    const handleViewDetails = (row) => {
        setSelectedRecord(row.scheduleInputs);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedRecord(null);
    };

    const handleDelete = (row) => {
        swal({
            title: "Are you sure to delete this app?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
              showLoader();
              let data = JSON.stringify({
                "rs_id": String(row.id)
              });
        
              let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: Base_URL + '/anomaly/reload_schedule',
                headers: { 
                  'Content-Type': 'application/json',
                },
                data : data
              };
        
              axios.request(config)
              .then((response) => {
                hideLoader();
                swal("App deleted successfully", {
                  icon: "success",
                }).then(() => {
                    fetchReloadSchedules();
                });          
              })
              .catch((error) => {
                hideLoader();
                console.log(error);
              });
            } else {
              hideLoader();
              swal("App not deleted");
            }
          });
    };

    const handleStatusToggle = (row) => {
        showLoader();
        let data = JSON.stringify({
        "rs_id": row.id.toString(),
        "status": !row.isActive
        });

        let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: Base_URL + '/anomaly/reload_schedule/status',
        headers: { 
            'Content-Type': 'application/json', 
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
            hideLoader();
            swal("Status updated successfully", {
                icon: "success",
            }).then(() => {
                fetchReloadSchedules();
            });          
        })
        .catch((error) => {
            hideLoader();
            console.log(error);
        });
    }

    const columns = [
        { field: 'appName', headerName: 'App Name', headerClassName: 'super-app-theme--header', flex:1.5 , cellClassName: (params) => {
            return 'word-wrap';
        }},
        {
            field: 'reloadInfo',
            headerName: 'Reload Time',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            cellClassName: 'word-wrap',
            valueGetter: (params) => {
              const startDatetime = params.row.startDatetime || '-';
              const timeZone = params.row.timeZone || '';
              return `${startDatetime} ${timeZone ? `(${timeZone})` : ''}`;
            },
        },
        {
            field: 'reloadTag',
            headerName: 'Trigger Alert',
            headerClassName: 'super-app-theme--header',
            flex: 1.1,
            cellClassName: 'word-wrap',
            valueGetter: (params) => {
              const value = params.value || '';
              const tags = [];
              if (value.includes('schedule')) {
                tags.push('Missed Scheduled');
              }
              if (value.includes('anomaly')) {
                tags.push('Anomalous Duration');
              }
              return tags.join(' and ') || '-';
            },
        },
        {
            field: 'isActive',
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            flex: 0.5,
            cellClassName: 'word-wrap',
            valueGetter: (params) => (params.value ? "Active" : "Inactive"),
        },
        {
            field: 'updateStatus',
            headerName: 'Update Status',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            cellClassName: 'word-wrap',
            renderCell: (params) => {
              const isActive = params.row.isActive;
              return (
                <Button
                  variant="outlined"
                  color={isActive ? "error" : "success"}
                  size="small"
                  sx={{ width: '110px' }}
                  onClick={() => handleStatusToggle(params.row)}
                >
                  {isActive ? "Deactivate" : "Activate"}
                </Button>
              );
            },
        },          
        {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            cellClassName: (params) => {
                return 'word-wrap';
            },
            renderCell: (params) => (
                <>
                    <IconButton
                        color="black"
                        onClick={() => handleViewOpen(params.row)}
                        style={{ marginLeft: '-15px' }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={() => handleEditOpen(params.row)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        color="error"
                        onClick={() => handleDelete(params.row)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            )
        }
    ];

    const rows = reloadSchedules.map((schedule) => ({
        id: schedule.id,
        appName: schedule.appName,
        startDatetime: schedule.scheduleInputs.startDateTime,
        cronTabExpression: schedule.cronTabExpression,
        delayOffset: schedule.delayOffset,
        reloadTag: schedule.reloadTag.join(', '),
        scheduleInputs: schedule.scheduleInputs,
        timeZone: schedule.scheduleInputs.timezone,
        isActive: schedule.is_active
    }));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const menuProps = {
        PaperProps: {
            style: {
                maxHeight: 200,
                width: 250,
            },
        },
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
    };

    const formatDate = (date) => {
        return dayjs(date).format('DD-MM-YYYY HH:mm');
    };

    const [appList, setAppList] = useState([]);
    const [selectedApp, setSelectedApp] = useState('');

    const [isScheduled, setIsScheduled] = useState(false);
    const [isAnomaly, setIsAnomaly] = useState(false);
    const [buffervalue, setbuffervalue] = useState(''); 

    const [startDateTime, setStartDateTime] = useState('');
    const [selectedFrequency, setSelectedFrequency] = useState('');
    const [selectedTimeZone, setSelectedTimeZone] = useState({});
    const [enteredHour, setEneterdHour] = useState('');
    const [enteredMins, setEneterdMins] = useState('');
    const [enteredDaily, setEneterdDaily] = useState('');
    const [enteredWeekly, setEneterdWeeky] = useState('');
    const [selectedDays, setSelectedDays] = useState([]);
    const [enteredMonthly, setEneterdMonthly] = useState([]);

    const daysOfWeek = [
        { label: "Mon", value: "MO" },
        { label: "Tues", value: "TU" },
        { label: "Wed", value: "WE" },
        { label: "Thurs", value: "TH" },
        { label: "Fri", value: "FR" },
        { label: "Sat", value: "SA" },
        { label: "Sun", value: "SU" },
    ];

    const handleChange = (event) => {
        setSelectedApp(event.target.value);
    };

    const handleScheduledChange = (event) => {
        setIsScheduled(event.target.checked);
    };

    const handleAnomalyChange = (event) => {
        setIsAnomaly(event.target.checked);
    };

    const handlebuffervalueChange = (event) => {
        setbuffervalue(event.target.value);
    };
    
    const handlehourvalueChange = (event) => {
        setEneterdHour(event.target.value);
    };

    const handleminsvalueChange = (event) => {
        setEneterdMins(event.target.value);
    };

    const handledailyvalueChange = (event) => {
        setEneterdDaily(event.target.value);
    };

    const handleweeklyvalueChange = (event) => {
        setEneterdWeeky(event.target.value);
    };

    const handleCheckboxChange = (day) => {
        setSelectedDays((prev) =>
            prev.includes(day)
                ? prev.filter((d) => d !== day)
                : [...prev, day]
        );
    };

    const handlemonthlyvalueChange = (event) => {
        const value = event.target.value;
        setEneterdMonthly((prev) =>
            prev.includes(Number(value))
                ? prev.filter((item) => item !== Number(value))
                : [...prev, Number(value)]
        );
    };

    const handleDateClick = (date) => {
        setEneterdMonthly((prev) =>
        prev.includes(date)
            ? prev.filter((d) => d !== date)
            : [...prev, date]
        );
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setSelectedApp('');
        setIsScheduled(false);
        setIsAnomaly(false);
        setbuffervalue(''); 
        setStartDateTime('');
        setSelectedFrequency('');
        setSelectedTimeZone({});
        setEneterdHour('');
        setEneterdMins('');
        setEneterdDaily('');
        setEneterdWeeky('');
        setSelectedDays([]);
        setEneterdMonthly([]);
        setOpenEdit(false);
        setOpen(false);
    };

    const [openedit, setOpenEdit] = React.useState(false);
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => {
        setSelectedApp('');
        setIsScheduled(false);
        setIsAnomaly(false);
        setbuffervalue(''); 
        setStartDateTime('');
        setSelectedFrequency('');
        setSelectedTimeZone({});
        setEneterdHour('');
        setEneterdMins('');
        setEneterdDaily('');
        setEneterdWeeky('');
        setSelectedDays([]);
        setEneterdMonthly([]);
        setRowInfo({});
        setId('');
        setAppName('');
        setOpenEdit(false);
    }

    const [openview, setOpenView] = React.useState(false);
    const handleOpenView = () => setOpenView(true);
    const handleCloseView = () => {
        setSelectedApp('');
        setIsScheduled(false);
        setIsAnomaly(false);
        setbuffervalue(''); 
        setStartDateTime('');
        setSelectedFrequency('');
        setSelectedTimeZone({});
        setEneterdHour('');
        setEneterdMins('');
        setEneterdDaily('');
        setEneterdWeeky('');
        setSelectedDays([]);
        setEneterdMonthly([]);
        setRowInfo({});
        setId('');
        setAppName('');
        setOpenView(false);
    }

    const [rowinfo, setRowInfo] = React.useState({});
    const [id, setId] = React.useState('');
    const [appname, setAppName] = React.useState('');

    const handleEditOpen = (rowinfo) => {
        setRowInfo(rowinfo);
        setId(rowinfo.id);
        setAppName(rowinfo.appName);
        const reloadTag = rowinfo.reloadTag;
        if (reloadTag === "schedule, anomaly") {
            setIsScheduled(true);
            setIsAnomaly(true);
        } else if (reloadTag === "schedule") {
            setIsScheduled(true);
            setIsAnomaly(false);
        } else if (reloadTag === "anomaly") {
            setIsScheduled(false);
            setIsAnomaly(true);
        } else {
            setIsScheduled(false);
            setIsAnomaly(false);
        }
        setbuffervalue(rowinfo?.delayOffset);
        setStartDateTime(dayjs(rowinfo?.scheduleInputs?.startDateTime, "DD-MM-YYYY HH:mm"))
        const timezone = rowinfo?.scheduleInputs?.timezone || "Asia/Kolkata";
        setSelectedTimeZone({ value: timezone, label: timezone });
        const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        setSelectedFrequency(
            rowinfo?.scheduleInputs?.option 
              ? capitalizeFirstLetter(rowinfo.scheduleInputs.option) 
              : "Once"
        );
        setEneterdHour(rowinfo?.scheduleInputs?.repeatInterval?.hours);
        setEneterdMins(rowinfo?.scheduleInputs?.repeatInterval?.minutes);
        setEneterdDaily(rowinfo?.scheduleInputs?.repeatEveryDays);
        setEneterdWeeky(rowinfo?.scheduleInputs?.weekly?.repeatEveryWeeks);
        setSelectedDays(rowinfo?.scheduleInputs?.weekly?.weekdays || []);
        setEneterdMonthly(rowinfo?.scheduleInputs?.monthly?.daysOfMonth || [])
        handleOpenEdit();
    };

    const handleViewOpen = (rowinfo) => {
        setRowInfo(rowinfo);
        setId(rowinfo.id);
        setAppName(rowinfo.appName);
        const reloadTag = rowinfo.reloadTag;
        if (reloadTag === "schedule, anomaly") {
            setIsScheduled(true);
            setIsAnomaly(true);
        } else if (reloadTag === "schedule") {
            setIsScheduled(true);
            setIsAnomaly(false);
        } else if (reloadTag === "anomaly") {
            setIsScheduled(false);
            setIsAnomaly(true);
        } else {
            setIsScheduled(false);
            setIsAnomaly(false);
        }
        setbuffervalue(rowinfo?.delayOffset);
        setStartDateTime(dayjs(rowinfo?.scheduleInputs?.startDateTime, "DD-MM-YYYY HH:mm"))
        setSelectedTimeZone(rowinfo?.scheduleInputs?.timezone || {});
        const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        setSelectedFrequency(
            rowinfo?.scheduleInputs?.option 
              ? capitalizeFirstLetter(rowinfo.scheduleInputs.option) 
              : "Once"
        );          
        setEneterdHour(rowinfo?.scheduleInputs?.repeatInterval?.hours);
        setEneterdMins(rowinfo?.scheduleInputs?.repeatInterval?.minutes);
        setEneterdDaily(rowinfo?.scheduleInputs?.repeatEveryDays);
        setEneterdWeeky(rowinfo?.scheduleInputs?.weekly?.repeatEveryWeeks);
        setSelectedDays(rowinfo?.scheduleInputs?.weekly?.weekdays || [""]);
        setEneterdMonthly(rowinfo?.scheduleInputs?.monthly?.daysOfMonth || [""])
        handleOpenView();
    };

    useEffect(() => {
        const fetchAppList = async () => {
            try {
                const config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: Base_URL + '/anomaly/app_list',
                    headers: {}
                };
                const response = await axios.request(config);
                setAppList(response.data.app_list);
            } catch (error) {
                console.error('Error fetching app list:', error);
            }
        };

        fetchAppList();
    }, []);

    const handleAdd = () => {
        showLoader();
        let data;
        const isHourly = selectedFrequency.toLowerCase() === "hourly";
        const isDaily = selectedFrequency.toLowerCase() === "daily";
        const isWeekly = selectedFrequency.toLowerCase() === "weekly";
        const isMonthly = selectedFrequency.toLowerCase() === "monthly";

        if(selectedApp === ''){
            hideLoader();
            window.alert("Please select app to continue.")
            return;
        }

        if(isScheduled === false && isAnomaly === false){
            hideLoader();
            window.alert("Please select trigger alert to continue.")
            return;
        }

        if(isScheduled && startDateTime === ''){
            hideLoader();
            window.alert("Please select reload time to continue.")
            return;
        }

        if(isScheduled && buffervalue === ''){
            hideLoader();
            window.alert("Please enter buffer time to continue.")
            return;
        }

        if (isScheduled && Object.keys(selectedTimeZone).length === 0) {
            hideLoader();
            window.alert("Please select a timezone to continue.");
            return;
        }

        if (isScheduled && selectedFrequency === '') {
            hideLoader();
            window.alert("Please select an frequency to continue.");
            return;
        }

        if (isScheduled && selectedFrequency === 'Hourly' && enteredHour === '') {
            hideLoader();
            window.alert("Please enter hour to continue.");
            return;
        }

        if (isScheduled && enteredHour !== '' && (enteredHour < 0 || enteredHour > 24)) {
            hideLoader();
            window.alert("Please enter hour in a range of 0 to 24 to continue.");
            return;
        }        

        if (isScheduled && selectedFrequency === 'Hourly' && enteredMins === '') {
            hideLoader();
            window.alert("Please enter mins to continue.");
            return;
        }

        if (isScheduled && enteredMins !== '' && (enteredMins < 0 || enteredMins > 60)) {
            hideLoader();
            window.alert("Please enter mins in a range of 0 to 60 to continue.");
            return;
        }        
        
        if (isScheduled && selectedFrequency === 'Daily' && enteredDaily === '') {
            hideLoader();
            window.alert("Please enter number of days to continue.");
            return;
        }

        if (isScheduled && selectedFrequency === 'Weekly' && enteredWeekly === '') {
            hideLoader();
            window.alert("Please enter number of weeks to continue.");
            return;
        }

        if (isScheduled && selectedFrequency === 'Weekly' && selectedDays.length === 0) {
            hideLoader();
            window.alert("Please select at least one day to continue.");
            return;
        }
        
        if (isScheduled && selectedFrequency === 'Monthly' && enteredMonthly.length === 0) {
            hideLoader();
            window.alert("Please at least one day to continue.");
            return;
        }

        if (isScheduled && isAnomaly){
            data = JSON.stringify({
                "app_name": selectedApp,
                "start_datetime": formatDate(startDateTime),
                "schedule_input": {
                  "option": selectedFrequency.toLowerCase(),
                  "timezone": selectedTimeZone.value,
                  "startDateTime": formatDate(startDateTime),
                    ...(isHourly && {
                    "repeatInterval": {
                        "hours": Number(enteredHour),
                        "minutes": Number(enteredMins)
                    }
                    }),
                    ...(isDaily && {
                        "repeatEveryDays": Number(enteredDaily)
                    }),
                    ...(isWeekly && {
                        "weekly": {
                            "repeatEveryWeeks": Number(enteredWeekly),
                            "weekdays": selectedDays
                        }
                    }),
                    ...(isMonthly && {
                        "monthly":{
                            "daysOfMonth": enteredMonthly
                        },
                    })
                },
                "delay_offset": Number(buffervalue),
                "reload_tag": [
                  "schedule",
                  "anomaly"
                ]
              });
        }else if(isScheduled){
            data = JSON.stringify({
                "app_name": selectedApp,
                "start_datetime": formatDate(startDateTime),
                "schedule_input": {
                  "option": selectedFrequency.toLowerCase(),
                  "timezone": selectedTimeZone.value,
                  "startDateTime": formatDate(startDateTime),
                    ...(isHourly && {
                    "repeatInterval": {
                        "hours": Number(enteredHour),
                        "minutes": Number(enteredMins)
                    }
                    }),
                    ...(isDaily && {
                        "repeatEveryDays": Number(enteredDaily)
                    }),
                    ...(isWeekly && {
                        "weekly": {
                            "repeatEveryWeeks": Number(enteredWeekly),
                            "weekdays": selectedDays
                        }
                    }),
                    ...(isMonthly && {
                        "monthly":{
                            "daysOfMonth": enteredMonthly
                        },
                    })
                },
                "delay_offset": Number(buffervalue),
                "reload_tag": [
                  "schedule"
                ]
              });
        }else{
            data = JSON.stringify({
              "app_name": selectedApp,
              "reload_tag":["anomaly"]
            });
        }
        
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: Base_URL + '/anomaly/reload_schedule',
          headers: { 
            'Content-Type': 'application/json', 
          },
          data : data
        };
        
        axios.request(config)
        .then((response) => {
          hideLoader();
          swal("App Added Successfully", {
            icon: "success",
          }).then(() => {
            handleClose();
            fetchReloadSchedules();
          });
        })
        .catch((error) => {
          hideLoader();
          console.log(error);
        });
      }

        const handleEdit = () => {
            showLoader();
            let data;
            const isHourly = selectedFrequency.toLowerCase() === "hourly";
            const isDaily = selectedFrequency.toLowerCase() === "daily";
            const isWeekly = selectedFrequency.toLowerCase() === "weekly";
            const isMonthly = selectedFrequency.toLowerCase() === "monthly";
            
            if(isScheduled === false && isAnomaly === false){
                hideLoader();
                window.alert("Please select trigger alert to continue.")
                return;
            }
    
            if (isScheduled && !startDateTime.isValid()) {
                hideLoader();
                window.alert("Please select reload time to continue.");
                return;
            }            
    
            if(isScheduled && buffervalue === 0){
                hideLoader();
                window.alert("Please enter a non zero number for buffer time.")
                return;
            }
    
            if (isScheduled && Object.keys(selectedTimeZone).length === 0) {
                hideLoader();
                window.alert("Please select a timezone to continue.");
                return;
            }
    
            if (isScheduled && selectedFrequency === '') {
                hideLoader();
                window.alert("Please select an frequency to continue.");
                return;
            }
    
            if (isScheduled && selectedFrequency === 'Hourly') {
                if (!enteredHour || String(enteredHour).trim() === '') {
                    hideLoader();
                    window.alert("Please enter hour to continue.");
                    return;
                }
            }            
    
            if (isScheduled && enteredHour !== '' && (enteredHour < 0 || enteredHour > 24)) {
                hideLoader();
                window.alert("Please enter hour in a range of 0 to 24 to continue.");
                return;
            }        
    
            if (isScheduled && selectedFrequency === 'Hourly') {
                if (!enteredMins || String(enteredMins).trim() === '') {
                    hideLoader();
                    window.alert("Please enter mins to continue.");
                    return;
                }
            }   
    
            if (isScheduled && enteredMins !== '' && (enteredMins < 0 || enteredMins > 60)) {
                hideLoader();
                window.alert("Please enter mins in a range of 0 to 60 to continue.");
                return;
            }        
            
            if (isScheduled && selectedFrequency === 'Daily') {
                if (!enteredDaily || String(enteredDaily).trim() === '') {
                    hideLoader();
                    window.alert("Please enter number of days to continue.");
                    return;
                }
            }
    
            if (isScheduled && selectedFrequency === 'Weekly') {
                if (!enteredWeekly || String(enteredWeekly).trim() === '') {
                    hideLoader();
                    window.alert("Please enter number of weeks to continue.");
                    return;
                }
            }  

            if (isScheduled && selectedFrequency === 'Weekly' && (!Array.isArray(selectedDays) || selectedDays.length === 0)) {
                hideLoader();
                window.alert("Please select at least one day to continue.");
                return;
            }            
            
            if (isScheduled && selectedFrequency === 'Monthly' && enteredMonthly.length === 0) {
                hideLoader();
                window.alert("Please at least one day to continue.");
                return;
            }

            if (isScheduled && isAnomaly){
                data = JSON.stringify({
                    "rs_id": id.toString(),
                    "app_name": appname,
                    "start_datetime": formatDate(startDateTime),
                    "schedule_input": {
                      "option": selectedFrequency.toLowerCase(),
                      "timezone": selectedTimeZone.value,
                      "startDateTime": formatDate(startDateTime),
                        ...(isHourly && {
                        "repeatInterval": {
                            "hours": Number(enteredHour),
                            "minutes": Number(enteredMins)
                        }
                        }),
                        ...(isDaily && {
                            "repeatEveryDays": Number(enteredDaily)
                        }),
                        ...(isWeekly && {
                            "weekly": {
                                "repeatEveryWeeks": Number(enteredWeekly),
                                "weekdays": selectedDays
                            }
                        }),
                        ...(isMonthly && {
                            "monthly":{
                                "daysOfMonth": enteredMonthly
                            },
                        })
                    },
                    "delay_offset": Number(buffervalue),
                    "reload_tag": [
                      "schedule",
                      "anomaly"
                    ]
                  });
            }else if(isScheduled){
                data = JSON.stringify({
                    "rs_id": id.toString(),
                    "app_name": appname,
                    "start_datetime": formatDate(startDateTime),
                    "schedule_input": {
                      "option": selectedFrequency.toLowerCase(),
                      "timezone": selectedTimeZone.value,
                      "startDateTime": formatDate(startDateTime),
                        ...(isHourly && {
                        "repeatInterval": {
                            "hours": Number(enteredHour),
                            "minutes": Number(enteredMins)
                        }
                        }),
                        ...(isDaily && {
                            "repeatEveryDays": Number(enteredDaily)
                        }),
                        ...(isWeekly && {
                            "weekly": {
                                "repeatEveryWeeks": Number(enteredWeekly),
                                "weekdays": selectedDays
                            }
                        }),
                        ...(isMonthly && {
                            "monthly":{
                                "daysOfMonth": enteredMonthly
                            },
                        })
                    },
                    "delay_offset": Number(buffervalue),
                    "reload_tag": [
                      "schedule"
                    ]
                  });
            }else{
                data = JSON.stringify({
                    "rs_id": id.toString(),
                    "app_name": appname,
                    "reload_tag":["anomaly"]
                });
            }

            let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: Base_URL + '/anomaly/reload_schedule',
            headers: { 
                'Content-Type': 'application/json', 
            },
            data : data
            };
            
            axios.request(config)
            .then((response) => {
                hideLoader();
                swal("App Updated Successfully", {
                    icon: "success",
                }).then(() => {
                    handleCloseEdit();
                    fetchReloadSchedules();
                  });
            })
            .catch((error) => {
                hideLoader();
                console.log(error);
            });
        };
    
    return (
        <>
            <br />
            <Button variant='outlined' color='primary' style={{marginLeft: '1%'}} onClick={handleOpen}>Add App <AddCircleOutlineIcon style={{ marginLeft: '5px' }} /></Button>
            <Box
                style={{ height: 'auto', width: '98%', marginLeft: '1%' }}
                sx={{
                    '& .super-app-theme--header': {
                    backgroundColor: '#060694',
                    color: 'white',
                    fontSize: '19px'
                    },
                }}
            >
                <br />
                <div style={{ height: 380, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5, 10, 20]}
                        rowSelection={false}
                    />
                </div>
            </Box>
            <br />
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Schedule Info
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                            {JSON.stringify(selectedRecord, null, 2)}
                        </pre>
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCloseModal}
                        sx={{ mt: 2 }}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...style, maxHeight: "80vh", overflowY: "auto" }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Add Application
                    </Typography>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="app-list-label">Application</InputLabel>
                        <Select
                            labelId="app-list-label"
                            value={selectedApp}
                            onChange={handleChange}
                            label="Application"
                            MenuProps={menuProps}
                        >
                            {appList.map((app, index) => (
                                <MenuItem key={index} value={app}>
                                    {app}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    Trigger Alert : &nbsp;
                    <FormControlLabel
                        control={<Checkbox checked={isScheduled} onChange={handleScheduledChange} />}
                        label="Missed Scheduled"
                        sx={{ mt: 0 }}
                        style={{ marginTop: '5px'}}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isAnomaly} onChange={handleAnomalyChange} />}
                        label="Anomalous Duration"
                        sx={{ mt: 0 }}
                        style={{ marginTop: '5px'}}
                    />
                    {isScheduled && (
                        <>
                        <Box sx={{ display: 'flex', gap: 2, mt: 0 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="Reload Time"
                                    onChange={(newValue) => setStartDateTime(newValue)}
                                    ampm={false}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                            <TextField
                                label="Buffer (in mins)"
                                value={buffervalue}
                                onChange={handlebuffervalueChange}
                                type="number"
                                inputProps={{ min: 0 }}
                                onInput={(e) => {
                                    if (parseInt(e.target.value) < 0) {
                                        e.target.value = '0';
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            Select Timezone :
                            <TimezoneSelect
                                value={selectedTimeZone}
                                onChange={setSelectedTimeZone}
                                priorityZone="auto"
                            />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <RadioGroup
                                row
                                value={selectedFrequency}
                                onChange={(e) => setSelectedFrequency(e.target.value)}
                            >
                                {['Once', 'Hourly', 'Daily', 'Weekly', 'Monthly'].map((freq) => (
                                    <FormControlLabel
                                        key={freq}
                                        value={freq}
                                        control={<Radio />}
                                        label={freq}
                                        sx={{ mr: 2 }}
                                    />
                                ))}
                            </RadioGroup>
                        </Box>
                        {
                            selectedFrequency === "Hourly" ?
                            <>
                            Repeat after each :
                            <br />
                            <TextField
                                label="Hour(s)"
                                value={enteredHour}
                                onChange={handlehourvalueChange}
                                type="number"
                                onInput={(e) => {
                                    if (parseInt(e.target.value) < 0) {
                                        e.target.value = '0';
                                    }
                                }}
                                style={{ marginTop: '2%' }}
                            />
                            <TextField
                                label="Minute(s)"
                                value={enteredMins}
                                onChange={handleminsvalueChange}
                                style={{ marginLeft: '5%', marginTop: '2%' }}
                                type="number"
                                onInput={(e) => {
                                    if (parseInt(e.target.value) < 0) {
                                        e.target.value = '0';
                                    }
                                }}
                            />
                            </>
                            :
                            selectedFrequency === "Daily" ?
                            <TextField
                                label="Every Day(s)"
                                value={enteredDaily}
                                onChange={handledailyvalueChange}
                                type="number"
                                onInput={(e) => {
                                    if (parseInt(e.target.value) < 0) {
                                        e.target.value = '0';
                                    }
                                }}
                            />
                            :
                            selectedFrequency === "Weekly" ?
                            <>
                            <TextField
                                label="Every Week(s)"
                                value={enteredWeekly}
                                onChange={handleweeklyvalueChange}
                                type="number"
                                onInput={(e) => {
                                    if (parseInt(e.target.value) < 0) {
                                        e.target.value = '0';
                                    }
                                }}
                            />
                            <Typography variant="body1" sx={{ mb: 1 }}>
                                Select Days of the Week:
                            </Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                                {daysOfWeek.map((day) => (
                                    <FormControlLabel
                                        key={day.value}
                                        control={
                                            <Checkbox
                                                checked={selectedDays.includes(day.value)}
                                                onChange={() => handleCheckboxChange(day.value)}
                                            />
                                        }
                                        label={day.label}
                                    />
                                ))}
                            </Box>
                            </>
                            :
                            selectedFrequency === "Monthly" ?
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(7, 1fr)',
                                    gap: 1,
                                    maxWidth: 300,
                                }}
                            >
                            {[...Array(31).keys()].map((day) => {
                                const date = day + 1;
                                return (
                                <Button
                                    key={date}
                                    variant={enteredMonthly.includes(date) ? 'contained' : 'outlined'}
                                    color={enteredMonthly.includes(date) ? 'primary' : 'secondary'}
                                    onClick={() => handleDateClick(date)}
                                >
                                    {date}
                                </Button>
                                );
                            })}
                            </Box>
                            :
                            ""
                        }
                        </>
                    )}
                    <br/>
                    <Button variant="outlined" onClick={handleAdd} sx={{ mt: 1 }}>
                        Add App
                    </Button>
                    <Button variant="outlined" onClick={handleClose} sx={{ mt: 1, ml: 2 }}>
                        Close
                    </Button>
                </Box>
            </Modal>
            <Modal
                open={openedit}
                onClose={handleCloseEdit}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...style, maxHeight: "80vh", overflowY: "auto" }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit Application
                    </Typography>
                    Trigger Alert : &nbsp;
                    <FormControlLabel
                        control={<Checkbox checked={isScheduled} onChange={handleScheduledChange} />}
                        label="Missed Scheduled"
                        sx={{ mt: 0 }}
                        style={{ marginTop: '5px'}}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isAnomaly} onChange={handleAnomalyChange} />}
                        label="Anomalous Duration"
                        sx={{ mt: 0 }}
                        style={{ marginTop: '5px'}}
                    />
                    {isScheduled && (
                        <>
                        <Box sx={{ display: 'flex', gap: 2, mt: 0 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="Reload Time"
                                    value={startDateTime}
                                    onChange={(newValue) => setStartDateTime(newValue)}
                                    ampm={false}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                            <TextField
                                label="Buffer (in mins)"
                                value={buffervalue}
                                onChange={handlebuffervalueChange}
                                type="number"
                                onInput={(e) => {
                                    if (parseInt(e.target.value) < 0) {
                                        e.target.value = '0';
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            Select Timezone :
                            <TimezoneSelect
                                value={selectedTimeZone}
                                onChange={setSelectedTimeZone}
                                priorityZone="auto"
                            />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <RadioGroup
                                row
                                value={selectedFrequency}
                                onChange={(e) => setSelectedFrequency(e.target.value)}
                            >
                                {['Once', 'Hourly', 'Daily', 'Weekly', 'Monthly'].map((freq) => (
                                    <FormControlLabel
                                        key={freq}
                                        value={freq}
                                        control={<Radio />}
                                        label={freq}
                                        sx={{ mr: 2 }}
                                    />
                                ))}
                            </RadioGroup>
                        </Box>
                        {
                            selectedFrequency === "Hourly" ?
                            <>
                            Repeat after each :
                            <br />
                            <TextField
                                label="Hour(s)"
                                value={enteredHour}
                                onChange={handlehourvalueChange}
                                type="number"
                                onInput={(e) => {
                                    if (parseInt(e.target.value) < 0) {
                                        e.target.value = '0';
                                    }
                                }}
                                style={{ marginTop: '2%' }}
                            />
                            <TextField
                                label="Minute(s)"
                                value={enteredMins}
                                onChange={handleminsvalueChange}
                                style={{ marginLeft: '5%', marginTop: '2%' }}
                                type="number"
                                onInput={(e) => {
                                    if (parseInt(e.target.value) < 0) {
                                        e.target.value = '0';
                                    }
                                }}
                            />
                            </>
                            :
                            selectedFrequency === "Daily" ?
                            <TextField
                                label="Every Day(s)"
                                value={enteredDaily}
                                onChange={handledailyvalueChange}
                                type="number"
                                onInput={(e) => {
                                    if (parseInt(e.target.value) < 0) {
                                        e.target.value = '0';
                                    }
                                }}
                            />
                            :
                            selectedFrequency === "Weekly" ?
                            <>
                            <TextField
                                label="Every Week(s)"
                                value={enteredWeekly}
                                onChange={handleweeklyvalueChange}
                                type="number"
                                onInput={(e) => {
                                    if (parseInt(e.target.value) < 0) {
                                        e.target.value = '0';
                                    }
                                }}
                            />
                            <Typography variant="body1" sx={{ mb: 1 }}>
                                Select Days of the Week:
                            </Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                                {daysOfWeek.map((day) => (
                                    <FormControlLabel
                                        key={day.value}
                                        control={
                                            <Checkbox
                                                checked={selectedDays.includes(day.value)}
                                                onChange={() => handleCheckboxChange(day.value)}
                                            />
                                        }
                                        label={day.label}
                                    />
                                ))}
                            </Box>
                            </>
                            :
                            selectedFrequency === "Monthly" ?
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(7, 1fr)',
                                    gap: 1,
                                    maxWidth: 300,
                                }}
                            >
                            {[...Array(31).keys()].map((day) => {
                                const date = day + 1;
                                return (
                                <Button
                                    key={date}
                                    variant={enteredMonthly.includes(date) ? 'contained' : 'outlined'}
                                    color={enteredMonthly.includes(date) ? 'primary' : 'secondary'}
                                    onClick={() => handleDateClick(date)}
                                >
                                    {date}
                                </Button>
                                );
                            })}
                            </Box>
                            :
                            ""
                        }
                        </>
                    )}
                    <br/>
                    <Button variant="contained" onClick={handleEdit} sx={{ mt: 1 }}>
                        Update App
                    </Button>
                    <Button variant="outlined" onClick={handleCloseEdit} sx={{ mt: 1, ml: 2 }}>
                        Close
                    </Button>
                </Box>
            </Modal>
            <Modal
                open={openview}
                onClose={handleCloseView}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...style, maxHeight: "80vh", overflowY: "auto" }}>
                    Trigger Alert : &nbsp;
                    <FormControlLabel
                        control={<Checkbox checked={isScheduled} onChange={handleScheduledChange} />}
                        label="Missed Scheduled"
                        sx={{ mt: 0 }}
                        style={{ marginTop: '5px'}}
                        disabled
                    />
                    <FormControlLabel
                        control={<Checkbox checked={isAnomaly} onChange={handleAnomalyChange} />}
                        label="Anomalous Duration"
                        sx={{ mt: 0 }}
                        style={{ marginTop: '5px'}}
                        disabled
                    />
                    {isScheduled && (
                        <>
                        <Box sx={{ display: 'flex', gap: 2, mt: 0 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="Reload Time"
                                    value={startDateTime}
                                    onChange={(newValue) => setStartDateTime(newValue)}
                                    ampm={false}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    disabled
                                />
                            </LocalizationProvider>
                            <TextField
                                label="Buffer (in mins)"
                                value={buffervalue}
                                onChange={handlebuffervalueChange}
                                disabled
                            />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            Selected Timezone :
                            <TimezoneSelect
                                value={selectedTimeZone}
                                onChange={setSelectedTimeZone}
                                priorityZone="auto"
                                isDisabled
                            />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <RadioGroup
                                row
                                value={selectedFrequency}
                                onChange={(e) => setSelectedFrequency(e.target.value)}
                            >
                                {['Once', 'Hourly', 'Daily', 'Weekly', 'Monthly'].map((freq) => (
                                    <FormControlLabel
                                        key={freq}
                                        value={freq}
                                        control={<Radio />}
                                        label={freq}
                                        sx={{ mr: 2 }}
                                        disabled
                                    />
                                ))}
                            </RadioGroup>
                        </Box>
                        {
                            selectedFrequency === "Hourly" ?
                            <>
                            <TextField
                                label="Hours"
                                value={enteredHour}
                                onChange={handlehourvalueChange}
                                disabled
                            />
                            <TextField
                                label="Minutes"
                                value={enteredMins}
                                onChange={handleminsvalueChange}
                                style={{ marginLeft: '5%' }}
                                disabled
                            />
                            </>
                            :
                            selectedFrequency === "Daily" ?
                            <TextField
                                label="Daily"
                                value={enteredDaily}
                                onChange={handledailyvalueChange}
                                disabled
                            />
                            :
                            selectedFrequency === "Weekly" ?
                            <>
                            <TextField
                                label="Weekly"
                                value={enteredWeekly}
                                onChange={handleweeklyvalueChange}
                                disabled
                            />
                            <Typography variant="body1" sx={{ mb: 1 }}>
                                Select Days of the Week:
                            </Typography>
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                                {daysOfWeek.map((day) => (
                                    <FormControlLabel
                                        key={day.value}
                                        control={
                                            <Checkbox
                                                checked={selectedDays.includes(day.value)}
                                                onChange={() => handleCheckboxChange(day.value)}
                                                disabled
                                            />
                                        }
                                        label={day.label}
                                    />
                                ))}
                            </Box>
                            </>
                            :
                            selectedFrequency === "Monthly" ?
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(7, 1fr)',
                                    gap: 1,
                                    maxWidth: 300,
                                }}
                            >
                            {[...Array(31).keys()].map((day) => {
                                const date = day + 1;
                                return (
                                <Button
                                    key={date}
                                    variant={enteredMonthly.includes(date) ? 'contained' : 'outlined'}
                                    color={enteredMonthly.includes(date) ? 'primary' : 'secondary'}
                                    onClick={() => handleDateClick(date)}
                                    disabled
                                >
                                    {date}
                                </Button>
                                );
                            })}
                            </Box>
                            :
                            ""
                        }
                        </>
                    )}
                    <br/>
                    <Button variant="contained" onClick={handleCloseView} sx={{ mt: 2 }}>
                        Close
                    </Button>
                </Box>
            </Modal>
            {loader}
        </>
    );
};

export default ReloadSchedule;