import React, { useState } from 'react';
import { Box, TextField, Button, Modal, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import wave1 from '../../images/wave_latest.svg';
import login from '../../images/login (1).svg';
import '../../App.css';
import wowlogo from '../../images/New Wowizer Logo 1.svg';
import { Base_URL } from '../../constants';
import useFullPageLoader from '../hooks/useFullPageLoader';
import Swal from 'sweetalert';

const OTPInput = ({ length = 6, otpValue, onChange }) => {
  const handleChange = (element, index) => {
    const value = element.value;
    if (!isNaN(value) && value.length <= 1) {
      const newOtp = [...otpValue];
      newOtp[index] = value;
      onChange(newOtp);

      if (value && index < length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !otpValue[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  return (
    <Box display="flex" justifyContent="center" gap={1}>
      {otpValue.map((value, index) => (
        <TextField
          key={index}
          id={`otp-input-${index}`}
          variant="outlined"
          inputProps={{
            maxLength: 1,
            style: { textAlign: 'center', width: '2rem', height: '2rem', fontSize: '1.5rem' }
          }}
          value={value}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
      ))}
    </Box>
  );
};

const ResetMfaModal = ({ open, onClose, onSubmit }) => {
  const [resetCode, setResetCode] = useState("");

  const handleSubmit = () => {
    onSubmit(resetCode);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="reset-mfa-modal">
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
        <Typography variant="h6" component="h2" align="center">
          Enter Reset Code
        </Typography>
        <TextField
          fullWidth
          label="Reset Code"
          value={resetCode}
          onChange={(e) => setResetCode(e.target.value)}
          margin="normal"
        />
        <Box mt={2} display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const ValidateOtp = () => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
  };

  const handleSubmit = () => {
    showLoader();
    const otpValue = otp.join("");

    const data = JSON.stringify({ totp: otpValue });
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Base_URL + '/mfaauth/validate',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios.request(config)
      .then((response) => {
        if(response){
          const userConfig = {
            method: 'get',
            url: Base_URL + '/users/me',
            headers: {}
          };

          axios(userConfig)
            .then((response) => {
              hideLoader();
              const planId = response.data.data.subscription.planId;
              const role = response.data.data.user.role;
              if (role === "soc_user") {
                window.location.href = '/adminportal';
              } else if (planId === "Standard-Realtime") {
                window.location.href = '/analyzelogs/realtimelogs/QV00/3';
              } else {
                window.location.href = '/analyzelogs/realtimelogs/QV00/3';
              }
            })
            .catch((error) => { console.log(error); });
        }
      })
      .catch((error) => {
        hideLoader();
        Swal({
          title: error.response ? error.response.data.msg : 'An unexpected error occurred.',
          icon: 'error',
          confirmButtonText: 'Try Again'
        });
      });
  };

  const handleResetMfaSubmit = async () => {
    showLoader();
    try {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/mfaauth/reset_mail',
        headers: {},
        data: ''
      };
  
      const response = await axios.request(config);
  
      if (response.status === 200) {
        hideLoader();
        Swal("Success", "Reset code sent successfully. Please check your email", "success")
          .then(() => {
            Swal({
              title: "Enter Reset Code",
              text: "Please enter the reset code sent to your email:",
              content: "input",
              button: {
                text: "Submit",
                closeModal: true,
              }
            }).then(async (resetCode) => {
              showLoader();
              if (resetCode) {
                const data = { code: resetCode };
  
                const resetConfig = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: Base_URL + '/mfaauth/reset',
                  headers: { 'Content-Type': 'application/json' },
                  data: JSON.stringify(data)
                };
  
                try {
                  const resetResponse = await axios.request(resetConfig);
  
                  if (resetResponse.status === 200) {
                    hideLoader();
                    Swal("MFA Reset Successful!", "Your MFA has been successfully reset.", "success");
                    navigate("/")
                  }
                } catch (error) {
                  hideLoader();
                  console.error(error);
                  Swal({
                    title: error.response ? error.response.data.msg : 'An unexpected error occurred.',
                    icon: 'error',
                    confirmButtonText: 'Try Again'
                  });
                }
              } else {
                hideLoader();
                Swal("No Code Entered", "Please enter the reset code to proceed.", "info");
              }
            });
          });
      }
    } catch (error) {
      console.error(error);
      Swal("Error", "There was an issue sending the reset code. Please try again later.", "error");
    }
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  const isOtpComplete = otp.every(value => value.length === 1);

  return (
    <>
      <img className="wave" src={wave1} alt="wave" />
      <div className="container-login">
        <div className="img">
          <img className='login-image' src={login} alt="login visual" />
        </div>
        <div className="login-content">
          <form className='form-class-login' onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            <div className="row">
              <div className="col">
                <img className='actionate-img' src={wowlogo} width="90%" style={{ marginLeft: '-2%' }} alt="logo" />
              </div>
            </div>
            <div className="col">
              <br />
              <div style={{ fontSize: '20px', color: '#000080' }}>
                To log in, enter the OTP from your authenticator app.
              </div>
              <br />
              <OTPInput length={6} otpValue={otp} onChange={handleOtpChange} />
              <br />
              <Box display="flex" gap={2} justifyContent="center">
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleSubmit}
                  disabled={!isOtpComplete}
                >
                  Submit OTP
                </Button>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleResetMfaSubmit}
                >
                  Reset MFA
                </Button>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleBack}
                >
                  Back
                </Button>
              </Box>
            </div>
          </form>
        </div>
      </div>
      {loader}
      <ResetMfaModal 
        open={isResetModalOpen} 
        onClose={() => setIsResetModalOpen(false)} 
        onSubmit={handleResetMfaSubmit}
      />
    </>
  );
};

export default ValidateOtp;