import Login from "./views/Login";
import { BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import Register from "./views/Register";
import ResetPassword from "./views/ResetPassword";
import Home from "./views/Home";
import SampleLogs from "./views/SampleLogs";
import Analyze from "./views/Analyze";
import Subscription from "./views/Subscription";
import UpdatePassword from "./views/UpdatePassword";
import Help from "./views/Help";
import RealTimeLogs from "./views/RealTimeLogs";
import Feedback from "./views/Feedback";
import Anomaly from "./views/Anomaly";
import ClusterData from "./views/ClusterData";
import Alerting from "./views/Alerting";
import AlertConfig from "./views/AlertConfig";
import AlertingDemo from "./views/AlertingDemo";
import ClusterDataDemo from "./views/ClusterDataDemo";
import ManageUsers from "./views/ManageUsers";
import TestDrive from "./views/TestDrive";
import TestDriveVerify from "./views/TestDriveVerify";
import Reporting from "./views/Reporting";
// import AlertConfig from "./views/AlertConfig";
import UseCase from "./views/UseCase";
import Archival from "./views/Archival";
import AlertingConfig from "./views/AlertingConfig.js";
import TestDriveLanding from "./views/TestDriveLanding.js";
import ClusterSearch from "./views/ClusterSearch.js";
import MlsearchDemo from "./views/MlsearchDemo.js";
import MLDiscover from "./views/MLDiscover.js";
// import Connectors from "./views/Connectors.js";
import Notifications from "./views/Notifications.js";
import AdminSideNav from "./components/AdminSideNav.js";
import AdminAlerts from "./views/AdminAlerts.js";
import Remediations from "./views/Remediations.js";
import AIDriven from "./views/AIDriven.js";
import MultiTenant from "./views/MultiTenant.js";
import MultiHeader from "./components/MultiHeader.js";
import Activities from "./views/Activities.js";
import ActivityCompare from "./views/ActivityCompare.js";
import AnomalyTabs from "./views/AnomalyTabs.js";
import HistoricalSampleLogs from "./views/HistoricalSampleLogs.js";
import RealTimeTroubleshoot from "./views/RealTimeTroubleshoot.js";
import HistoricalTroubleshoot from "./views/HistoricalTroubleshoot.js";
import GeneratedReports from "./views/GeneratedReports.js";
import SelectUploadTag from "./components/SelectUploadTag.js";
import ValidateOtp from "./components/MFA/ValidateOtp.js";
import GenerateMfa from "./components/MFA/GenerateMfa.js";
import MfaConfig from "./components/MFA/MfaConfig.js";
import GenerateMfaIn from "./components/MFA/GenerateMfaIn.js";
import AlertsTabs from "./views/AlertsTabs.js";

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/validateotp" element={<ValidateOtp />} />
        <Route path="/generatemfa" element={<GenerateMfa />} />
        <Route path="/generate_mfa" element={<GenerateMfaIn />} />
        <Route path="/features" element={<MfaConfig />} />
        <Route path="register" element={<Register />} />
        <Route path="resetpassword" element={<ResetPassword />} />
        <Route path="updatepassword/" element={<UpdatePassword />} />
        <Route path="home" element={<Home />} />
        <Route path="/analyzelogs/:uploadTag/:qlikversion/:id" element={<SampleLogs />} />
        <Route path="/analyzestaticlogs/:uploadTag/:qlikversion/:id" element={<HistoricalSampleLogs />} />
        <Route path="/clusterdata/:uploadTag" element={<AnomalyTabs />} />
        <Route path="/clusterdatademo/:uploadTag" element={<ClusterDataDemo />} />
        <Route path="/analyze" element={<Analyze />} />
        <Route path="/anomaly" element={<Anomaly />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/help" element={<Help />} />
        <Route path="/realtimelogs" element={<RealTimeLogs />} />
        <Route path="/alerting/hubsettings" element={<AlertConfig />} />
        <Route path="/alerting" element={<Alerting />} />
        <Route path="/alertingconfig" element={<AlertingConfig />} />
        <Route path="/alertingdemo" element={<AlertingDemo />} />
        {/* <Route path="/alertconfig/:monitorid" element={<AlertConfig />} /> */}
        <Route path="/reporting" element={<Reporting />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/manageusers" element={<ManageUsers />} />
        <Route path="/checkoutwowizer" element={<TestDrive />} />
        <Route path="/trialdrive" element={<TestDriveVerify />} />
        <Route path="/traildrive" element={<TestDriveVerify />} />
        <Route path="/welcome" element={<TestDriveLanding />} />
        <Route path="/usecase" element={<UseCase />} />
        <Route path="/archival" element={<Archival />} />
        <Route path="/alertstab" element={<AlertsTabs />} />
        {/* <Route path="/mlsearch" element={<ClusterSearch />} /> */}
        <Route path="/ai_insights/:uploadTag" element={<MlsearchDemo />} />
        <Route path="/mldiscover" element={<MLDiscover/>} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/adminportal" element={<AdminAlerts />} />
        <Route path="/autoremediation" element={<Remediations />} />
        <Route path="/aidrivendashboards" element={<AIDriven />} />
        <Route path="/selectcustomer" element={<MultiTenant />} />
        <Route path="/customerdashboard" element={<MultiHeader />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/activity-compare" element={<ActivityCompare />} />
        <Route path="/aitroubleshoot/realtimelogs" element={<RealTimeTroubleshoot />} />
        <Route path="/aitroubleshoot/:uploadTag" element={<HistoricalTroubleshoot />} />
        <Route path="/generatedreports" element={<GeneratedReports />} />
        <Route path="/selectuploadtag" element={<SelectUploadTag />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
