import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { Email, Lock } from '@mui/icons-material';
import wave1 from '../images/wave_latest.svg';
import login from '../images/login (1).svg';
import wowlogo from '../images/New Wowizer Logo 1.svg';
import '../App.css';
import { Base_URL, Update_Password } from '../constants';
import axios from 'axios';
import swal from 'sweetalert';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import jwt_decode from "jwt-decode";
import { useSearchParams } from "react-router-dom";

const UpdatePassword = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    let token = searchParams.get("token");
    const decoded = jwt_decode(token);
    const email = decoded.email;

    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,32}$/;
        return passwordRegex.test(password);
    };    

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        const password1 = document.getElementById("password1").value;
        const password2 = document.getElementById("password2").value;

        if (!validatePassword(password1)) {
            swal({
                title: "Error",
                text: "Password must be 12-32 characters long and contain at least one letter and one number",
                icon: "error",
            });
        } else if (password1 !== password2) {
            swal({
                title: "Error",
                text: "Passwords do not match",
                icon: "error",
            });
        } else {
            showLoader();
            var data = JSON.stringify({
                "password": password1,
                "passwordConfirm": password2
            });

            var config = {
                method: 'post',
                url: Base_URL + Update_Password + token,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    hideLoader();
                    swal({
                        title: "Password Updated Successfully",
                        text: "",
                        icon: "success",
                    }).then(function () {
                        window.location.href = '/'
                    });
                })
                .catch(function (error) {
                    hideLoader();
                    swal({
                        icon: 'error',
                        title: 'Error',
                        text: error.response.data.message,
                    });
                });
        }
    };

    return (
        <>
            <img className="wave" src={wave1} />
            <div className="container-login">
                <div className="img">
                    <img className='login-image' src={login} />
                </div>
                <div className="login-content">
                    <form className='form-class-login' onSubmit={handleLoginSubmit}>
                        <div className="row">
                            <div className="col">
                                <img className='actionate-img' src={wowlogo} width="90%" style={{ marginLeft: '-2%' }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextField
                                    id="email"
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={email}
                                    disabled
                                    InputProps={{
                                        startAdornment: <Email />,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextField
                                    id="password1"
                                    label="Password"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        startAdornment: <Lock />,
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextField
                                    id="password2"
                                    label="Confirm Password"
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                    value={confirmpassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    InputProps={{
                                        startAdornment: <Lock />,
                                    }}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <br />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ borderRadius: '50px' }}
                                    type='submit'
                                >
                                    Set Password
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
                {loader}
            </div>
        </>
    );
};

export default UpdatePassword;