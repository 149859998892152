import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, CircularProgress, Box, Button } from '@mui/material';
import Swal from 'sweetalert';
import SessionExpiredAlert from '../SessionExpiredAlert';
import RealTimeSideNav from '../RealTimeSideNav';
import HistoricalSideNav from '../HistoricalSideNav';
import Header from '../Header';
import Footer from '../Footer';
import { Base_URL, Logout_User } from '../../constants';
import useFullPageLoader from '../hooks/useFullPageLoader';
import mfalogo from '../../images/microsoft-authenticator.jpg'
import qliksense from '../../images/qliksense.png'

const MfaConfig = () => {
  const [mfaStatus, setMfaStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const currentNav = localStorage.getItem('currentNav');
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  useEffect(() => {
    const fetchMfaConfig = async () => {
      showLoader();
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: Base_URL + '/mfaauth/ready',
          headers: {}
        };
        
        const response = await axios.request(config);
        hideLoader();
        setMfaStatus(response.data);
      } catch (error) {
        hideLoader();
        console.error("Error fetching MFA config:", error);
      } finally {
        hideLoader();
        setLoading(false);
      }
    };

    fetchMfaConfig();
  }, []);

  const handleEnableMfa = async (enable = true) => {
    showLoader();
    try {
      const data = {
        is_mfa_enable: enable
      };
  
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/auth/mfa_config',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(data)
      };
  
      const response = await axios.request(config);
      hideLoader();
      Swal({
        icon: 'success',
        title: enable ? 'MFA Enabled successfully' : 'MFA Disabled successfully',
        text: enable ? 'Please Login Again' : '',
        confirmButtonText: 'OK'
      }).then(() => {
          if(enable){
            showLoader();
          var config = {
            method: 'get',
            url: Base_URL + Logout_User,
            headers: {}
          };
      
          axios(config)
              .then(function (response) {
                hideLoader();
                sessionStorage.clear();
                localStorage.clear();
                // Object.keys(cookies).forEach((cookieName) => {
                //   removeCookie(cookieName);
                // });
                window.location.href = '/';
              })
              .catch(function (error) {
                var config = {
                method: 'get',
                url: Base_URL + '/auth/refresh',
                headers: { }
                };
      
                axios(config)
                .then(function (response) {
                  var config = {
                    method: 'get',
                    url: Base_URL + Logout_User,
                    headers: {}
                  };
                  axios(config)
                  .then(function (response) {
                    hideLoader();
                    localStorage.clear();
                    // Object.keys(cookies).forEach((cookieName) => {
                    //   removeCookie(cookieName);
                    // });
                    window.location.href = '/';
                  })
                })
                .catch(function (error) {
                  hideLoader();
                  console.log(error);
                });
              });
          }else{
            window.location.reload();
          }
      });
    } catch (error) {
      console.error("Error changing MFA status:", error);
      hideLoader();
      Swal.fire({
        icon: 'error',
        title: 'Error changing MFA status',
        text: error.response?.data?.errors[0]?.message || "An unexpected error occurred"
      });
    }
  };

  const handleResetMfa = async () => {
    showLoader();
    try {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: Base_URL + '/mfaauth/reset_mail',
        headers: {},
        data: ''
      };
  
      const response = await axios.request(config);
  
      if (response.status === 200) {
        hideLoader();
        Swal("Success", "Reset code sent successfully. Please check your email", "success")
          .then(() => {
            Swal({
              title: "Enter Reset Code",
              text: "Please enter the reset code sent to your email:",
              content: "input",
              button: {
                text: "Submit",
                closeModal: true,
              }
            }).then(async (resetCode) => {
              showLoader();
              if (resetCode) {
                const data = { code: resetCode };
  
                const resetConfig = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: Base_URL + '/mfaauth/reset',
                  headers: { 'Content-Type': 'application/json' },
                  data: JSON.stringify(data)
                };
  
                try {
                  const resetResponse = await axios.request(resetConfig);
  
                  if (resetResponse.status === 200) {
                    hideLoader();
                    Swal("MFA Reset Successful!", "Your MFA has been successfully reset.", "success");
                    window.location.reload();
                  }
                } catch (error) {
                  hideLoader();
                  console.error(error);
                  Swal({
                    title: error.response ? error.response.data.msg : 'An unexpected error occurred.',
                    icon: 'error',
                    confirmButtonText: 'Try Again'
                  });
                }
              } else {
                hideLoader();
                Swal("No Code Entered", "Please enter the reset code to proceed.", "info");
              }
            });
          });
      }
    } catch (error) {
      console.error(error);
      Swal("Error", "There was an issue sending the reset code. Please try again later.", "error");
    }
  };  

  const handleUpdateFeatures = async (status, feature_name) => {
    showLoader();

    let data = JSON.stringify({
      "feature": feature_name,
      "status": status
    });

    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: Base_URL + '/users/subscription/features',
      headers: { 
        'Content-Type': 'application/json',    
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        hideLoader();
        Swal({
          title: '',
          text: response.data.msg,
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        hideLoader();
      });
  }; 

  const handleConfigureMfa = () => {
    window.open('/generate_mfa', '_blank');
  };

  const user = localStorage.getItem("userRole")

  const [features, setFeatures] = useState(null);
  
  useEffect(() => {
    const fetchSubscriptionFeatures = async () => {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: Base_URL + '/users/subscription/features',
        headers: {
          
        }
      };

      try {
        const response = await axios.request(config);
        setFeatures(response.data);
      } catch (error) {
        console.error('Error fetching subscription features:', error);
      }
    };

    fetchSubscriptionFeatures();
  }, []);

  return (
    <>
      <SessionExpiredAlert />
      {currentNav === 'Real Time' ? <RealTimeSideNav /> : <HistoricalSideNav />}
      <Header />
      <div className="content-wrapper">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="80vh"
        >
          <Card sx={{ maxWidth: 400, padding: 3, textAlign: 'center' }}>
            <img src={mfalogo} style={{ width: '50px' }}></img>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                MFA Config
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <div>
                  <Typography variant="body1">
                    <strong>Enabled:</strong> {mfaStatus?.mfa_enabled ? "Yes" : "No"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Configured:</strong> {mfaStatus?.mfa_configured ? "Yes" : "No"}
                  </Typography>

                  {mfaStatus?.mfa_enabled === false && (
                    <Button variant="contained" color="primary" onClick={() => handleEnableMfa(true)} sx={{ mt: 2 }}>
                      Enable
                    </Button>
                  )}
                  {mfaStatus?.mfa_enabled === true && mfaStatus?.mfa_configured === false && (
                    <>
                    {
                      user === "user_ro" ? "" : <Button variant="outlined" color="error" onClick={() => handleEnableMfa(false)} sx={{ mt: 2, mr: 1 }}>
                      Disable
                      </Button>
                    }

                    <Button variant="contained" color="primary" onClick={handleConfigureMfa} sx={{ mt: 2 }}>
                      Configure
                    </Button>
                    </>
                  )}
                  {mfaStatus?.mfa_enabled === true && mfaStatus?.mfa_configured === true && (
                    <>
                      <Button variant="outlined" color="error" onClick={handleResetMfa} sx={{ mt: 2, mr: 1 }}>
                        Reset
                      </Button>
                      {
                        user === "user_ro" ? "" : <Button variant="contained" color="primary" onClick={() => handleEnableMfa(false)} sx={{ mt: 2 }}>
                        Disable
                        </Button>
                      }
                    </>
                  )}
                </div>
              )}
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: 400, padding: 3, textAlign: 'center', marginLeft: '10%' }}>
            <img src={qliksense} style={{ width: '70px' }}></img>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                N Printing
              </Typography>
              {loading ? (
              <CircularProgress />
            ) : (
              <div>
                <Typography variant="body1">
                  <strong>Enabled:</strong> {features?.features?.nprinting === true ? "Yes" : "No"}
                </Typography>
                <br />
                {
                  features?.features?.nprinting === true ?
                  <Button variant="outlined" color="error" onClick={() => handleUpdateFeatures(false, "nprinting")} sx={{ mt: 2 }}>
                    Disable
                  </Button>
                  :
                  <Button variant="contained" color="primary" onClick={() => handleUpdateFeatures(true, "nprinting")} sx={{ mt: 2 }}>
                    Enable
                  </Button>
                }
                <br />
              </div>
            )}
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: 400, padding: 3, textAlign: 'center', marginLeft: '10%' }}>
            <img src={qliksense} style={{ width: '70px' }}></img>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                Sense
              </Typography>
              {loading ? (
                <CircularProgress />
              ) : (
                <div>
                  <Typography variant="body1">
                    <strong>Enabled:</strong> {features?.features?.qlik_sense === true ? "Yes" : "No"}
                  </Typography>
                  <br />
                  {
                    features?.features?.qlik_sense === true ?
                    <Button variant="outlined" color="error" onClick={() => handleUpdateFeatures(false, "qlik_sense")} sx={{ mt: 2 }}>
                      Disable
                    </Button>
                    :
                    <Button variant="contained" color="primary" onClick={() => handleUpdateFeatures(true, "qlik_sense")} sx={{ mt: 2 }}>
                      Enable
                    </Button>
                  }
                </div>
              )}
            </CardContent>
          </Card>
        </Box>
      </div>
      {loader}
      <Footer />
    </>
  );
};

export default MfaConfig;