import React, { useState } from 'react'
import Footer from '../components/Footer'
import SessionExpiredAlert from '../components/SessionExpiredAlert'
import SideNav from '../components/SideNav'
import Header from '../components/Header'
import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, Modal, Table, TableBody, TableContainer, TextField, Typography } from '@mui/material'
import { FcInfo } from 'react-icons/fc'
import swal from 'sweetalert';
import { Base_URL } from '../constants'
import axios from 'axios';
import { DataGrid, GridPagination } from '@mui/x-data-grid'
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom'
import RealTimeSideNav from '../components/RealTimeSideNav'
import HistoricalSideNav from '../components/HistoricalSideNav'
import useFullPageLoader from '../components/hooks/useFullPageLoader'

const AlertingConfig = () => {

  const [viewalertinfodata, setViewAlertInfoData] = React.useState({});
  const [alertinfoopen, setAlertInfoOpen] = React.useState(false);
  const [webhookname, setWebhookName] = React.useState();
  const [monitordata, setMonitorData] = React.useState({});
  const [modalpage, setModalPage] = useState(0);
  const [page, setPage] = useState(0);
  const handleAlertInfoClose = () => setAlertInfoOpen(false);
  const [openmonitor, setOpenMonitor] = useState(false);
  const [webhookurlexists, setWebhookUrlExists] = React.useState();
  const [webhookValue, setWebhookValue] = useState('');
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const webhookstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginLeft: '7%',
    height: 300,
    width: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const navigate = useNavigate();

  const AlertHubSetting = () => {
    navigate('/alerting/hubsettings');
  };

  const AlertInfoDataBlock=(props)=>{
    return( <>
    <TableContainer><Table><TableBody>
    {
        Object.keys(props.alertInfoData).map((x)=>
        {
            return (<Card><CardContent>
              {
                <div style={{display:'block', marginBottom: '-2%'}}>
                <div style={{display:'inline-block'}}> <b>{x}</b>:</div>
                <div style={{display:'inline-block'}}>  {props.alertInfoData[x]}  </div> 
                </div> 
              }
            </CardContent></Card>);
        }
        )
    } 
    </TableBody></Table></TableContainer>
    </>
    )
  }

  const viewdatastyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    marginLeft: '7%',
    height: 500,
    width: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow:"auto",
    p: 4,
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  function handleAlertInfoOpen(alert_info){
    setViewAlertInfoData({
      'data': alert_info
    });

    setAlertInfoOpen(true);
  }

  const handleMonitorsClick = () => {
      navigate('/notifications');
  };

  const handleMonitorCloseModal = () => {
    setOpenMonitor(false);
  };

  const [webhookstatus, setWebhookStatus] = React.useState();

  React.useEffect(() => {
    fetchWebhook();
  }, []);

  const fetchWebhook = () => {
    var config = {
      method: 'get',
      url: Base_URL + '/alerts/webhook',
      headers: { }
    };

    axios(config)
    .then(function (response) {
      setWebhookUrlExists(response.data.data.webhookconfig[0]['webhookUrl']);
      setWebhookName(response.data.data.webhookconfig[0]['webhookName']);
      setWebhookStatus(response.data.data.webhookconfig[0]['status']);
    })
    .catch(function (error) {
      
    });
  }

  const handleWebhookUpdate = () => {
    var data = JSON.stringify({
      "webhook_name": webhookname,
      "webhook_url": webhookValue
    });

    var config = {
      method: 'patch',
      url: Base_URL + '/alerts/webhook/',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      swal("Webhook URL Updated!", "", "success").then(function () {
        window.location.reload();
      });
    })
    .catch(function (error) {
      
    });
  }

  const SetupLink = () => {
    window.open('https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=dotnet', '_blank');
  };

  const handleWebhookSubmit = () => {
    var data = JSON.stringify({
      "webhook_url": webhookValue
    });

    var config = {
      method: 'post',
      url: Base_URL + '/alerts/webhook/',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      swal("Webhook Added!", "", "success").then(function () {
        window.location.reload();
      });
    })
    .catch(function (error) {
      
    });
  }

  const handleTeamsAlertClick = () => {
    swal({
      icon: 'info',
      title: 'WoWizer TPM Test Drive does not have the functionality!',
      text: "Kindly proceed with the complete sign-up to unlock all features.",
      buttons: {
        signUp: {
          text: "Complete Sign-up",
          value: "signUp",
          className: "black-text-button",
        },
        cancel: "Cancel",
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      dangerMode: true,
    })
      .then((value) => {
        if (value === "signUp") {
          const signUpPageUrl = "/register";
          window.open(signUpPageUrl, '_blank');
        }
      });      
  };

  function UpdateAll(status) {
    swal({
      title: "Are you sure to update settings for all?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        var data = JSON.stringify({
          "monitor_status": status
        });
        
        var config = {
          method: 'post',
          url:  Base_URL + '/alerts/monitors/status/',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {
          
        })
        .catch(function (error) {
          
        });
        swal("Settings Updated Successfully!", {
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      } else {
        swal("Settings not changed!");
      }
    });
  }

  function handleWebhookStatusChangeAll() {
    swal({
      title: "Are you sure to update the webhook?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        let status = webhookstatus;
        status = !status;
        
        var data = JSON.stringify({
            "webhook_name": webhookname,
            "webhook_status": status,
        });
        
        var config = {
          method: 'patch',
          url: Base_URL + '/alerts/webhook/status',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {
          
        })
        .catch(function (error) {
          
        });      
        swal("Setting Updated Successfully!", {
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      } else {
        swal("Settings not changed!");
      }
    });
  }

  function handleStatusChange(row) {
    swal({
      title: "Are you sure to update the setting?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        let status = row.monitor_status;
    
        status = !status;

        var data = JSON.stringify({
          "monitor_status": status,
          "monitor_id": row.monitor_id
        });
        
        var config = {
          method: 'post',
          url: Base_URL + '/alerts/monitor/status/',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {
          
        })
        .catch(function (error) {
          
        });      
        swal("Setting Updated Successfully!", {
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      } else {
        swal("Settings not changed!");
      }
    });
  }

  function handleWebhookStatusChange(row) {
    swal({
      title: "Are you sure to update the webhook?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        let status = row.webhook_status;
        
        status = !status;
        
        var data = JSON.stringify({
            "webhook_name": webhookname,
            "webhook_status": status,
            "monitor_id": row.monitor_id
        });
        
        var config = {
          method: 'patch',
          url: Base_URL + '/alerts/webhook/status',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        axios(config)
        .then(function (response) {
          
        })
        .catch(function (error) {
          
        });      
        swal("Setting Updated Successfully!", {
          icon: "success",
        }).then(() => {
          window.location.reload();
        });
      } else {
        swal("Settings not changed!");
      }
    });
  }

  React.useEffect(() => {
    handleAlertsClick();
  }, []);

  const handleAlertsClick = () => {
    var config = {
      method: 'get',
      url:  Base_URL + '/alerts/monitors/',
      headers: { }
    };
    
    axios(config)
    .then(function (response) {
      const MonitorData = response.data.data.map((element, index) => {
        return {
          "id" : index + 1,
          "monitor_id" : element.monitor_id,
          "monitor_name": element.monitor_name,
          "monitor_severity": element.monitor_severity,
          "monitor_status": element.monitor_status,
          "alert_info": element.wow_alert_info,
          "status": element.monitor_status,
          "webhook_status": element.webhook_status
        };
        });
        MonitorData.sort((a, b) => a.monitor_severity - b.monitor_severity);
        setMonitorData(MonitorData);
    })
    .catch(function (error) {
      
    });
  };

  const monitorcolumns = [    
    // { field: 'id', headerClassName: 'super-app-theme--header', headerName: 'ID', flex: 0.1, cellClassName: (params) => {
    //   return 'word-wrap';
    // } },
    { field: 'monitor_name', headerClassName: 'super-app-theme--header', headerName: 'Ruleset Name', flex: 0.9, cellClassName: (params) => {
      return 'word-wrap';
    } },  
    { field: 'monitor_severity', headerClassName: 'super-app-theme--header', headerName: 'Severity', flex: 0.2, cellClassName: (params) => {
      return 'word-wrap';
    } },  
    { field: 'status', headerClassName: 'super-app-theme--header', headerName: 'Ruleset Status', flex: 0.4, cellClassName: (params) => {
      return 'word-wrap';
    },
    renderCell: (params) => {
      const isMonitorEnabled = params.value;
      return isMonitorEnabled ? (
        <span style={{ color: 'green'}} >Enabled</span>
      ) : (
        <span style={{ color: 'red'}} >Disabled</span>
      );
    }, },
    {
      field: 'alert_info',
      headerClassName: 'super-app-theme--header',
      headerName: 'Info',
      flex: 0.3,
      cellClassName: (params) => {
        return 'word-wrap';
      },
      renderCell: (params) => (
        <><FcInfo onClick={() => handleAlertInfoOpen(params.value)} size={30} style={{ cursor: 'pointer' }}/>
        {/* <Button onClick={() => handleAlertInfoOpen(params.value)} variant="contained" color="success">View Data</Button> */}
        </>
      ),
    },
    {
      field: 'monitor_status',
      headerClassName: 'super-app-theme--header',
      headerName: 'Action',
      flex: 0.4,
      sortable: false,
      cellClassName: (params) => {
        return 'word-wrap';
      },
      renderCell: (params) => {
        const isMonitorEnabled = params.value;
        const trialuser = localStorage.getItem('userRole')
        return trialuser === "trialuser" ? (
          <div>
            {isMonitorEnabled ? (
              <Button size="small" onClick={handleTeamsAlertClick} variant="outlined" color="error">Disable</Button>
            ) : (
              <Button size="small" onClick={handleTeamsAlertClick} variant="contained" color="success">Enable</Button>
            )}
          </div>
        ) : (
          <div>
            {isMonitorEnabled ? (
              <Button size="small" onClick={() => handleStatusChange(params.row)} variant="outlined" color="error">Disable</Button>
            ) : (
              <Button size="small" onClick={() => handleStatusChange(params.row)} variant="contained" color="success">Enable</Button>
            )}
          </div>
        );
      },
    },
    // {
    //   field: 'webhook_status',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Webhook',
    //   flex: 0.4,
    //   sortable: false,
    //   cellClassName: (params) => {
    //     return 'word-wrap';
    //   },
    //   renderCell: (params) => {
    //     const isWebhookEnabled = params.value;
    //     const trialuser = localStorage.getItem('userRole')
    //     return trialuser === "trialuser" ? (
    //       <div>
    //         {isWebhookEnabled === true ? (
    //           <Button size="small" onClick={handleTeamsAlertClick} variant="outlined" color="error">Deactivate</Button>
    //         ) : isWebhookEnabled === false ? (
    //           <Button size="small" onClick={handleTeamsAlertClick} variant="contained" color="success">Activate</Button>
    //         )
    //         :
    //           <Button size="small" onClick={handleTeamsAlertClick} variant="contained" color="success" style={{ fontSize: '78%' }}>Not Configured</Button>
    //       }
    //       </div>
    //     ) : (
    //       <div>
    //         {isWebhookEnabled === true ? (
    //           <Button size="small" onClick={() => handleWebhookStatusChange(params.row)} variant="outlined" color="error">Deactivate</Button>
    //         ) : isWebhookEnabled === false ? (
    //           <Button size="small" onClick={() => handleWebhookStatusChange(params.row)} variant="contained" color="success">Activate</Button>
    //         ) : (
    //           <div>
    //             <Typography variant="body2">
    //               Not Configured
    //             </Typography>
    //           </div>
    //         )}
    //       </div>
    //     );
    //   },
    // }
  ];
  
  const currentNav = localStorage.getItem('currentNav');

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  
  const connectors = ["email", "teams", "whatsapp", "voice_call", "zoho_desk", "servicenow"];

  const handleOpen = () => {
    setOpen(true);
    fetchData();
  };

  const handleClose = () => {
    setOpen(false);
    setRows([]);
  };

  const fetchData = () => {
    showLoader();
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: Base_URL + '/alerts/connectors/map_alerts_connectors',
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        hideLoader();
        const data = response.data.alerts_connectors_mapping;
        const formattedRows = Object.entries(data).map(([key, alert]) => ({
          id: key,
          title: alert.title,
          connector_mapping: alert.connector_mapping,
        }));
        setRows(formattedRows);
        hideLoader();
      })
      .catch((error) => {
        console.error(error);
        hideLoader();
      });
  };

  const connectorDisplayNames = {
    whatsapp: "Whats App",
    voice_call: "Voice Call",
    zoho_desk: "Zoho Desk",
    servicenow: "Service Now",
    email: "Email",
    teams: "Teams",
  };
  
  const handleCheckboxChange = (id, connector) => (event) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === id
          ? {
              ...row,
              connector_mapping: event.target.checked
                ? [...row.connector_mapping, connector]
                : row.connector_mapping.filter((item) => item !== connector),
            }
          : row
      )
    );
  };  

  const handleUpdate = () => {
    showLoader();
    const alertsMapping = rows.reduce((acc, row) => {
      acc[row.id] = row.connector_mapping;
      return acc;
    }, {});

    const data = JSON.stringify({ alerts_mapping: alertsMapping });

    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: Base_URL + '/alerts/connectors/map_alerts_connectors',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        swal({
          icon: 'success',
          title: 'Success!',
          text: 'Mapping Updated Successfully!',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result) {
            window.location.reload();
          }
        });
      })
      .catch((error) => {
        hideLoader();
      });
  };

  const columns = [
    {
      field: 'title',
      headerClassName: 'super-app-theme--header',
      headerName: 'Title',
      cellClassName: (params) => {
        return 'word-wrap';
      },
      flex: 1,
    },
    {
      field: "connectors",
      headerClassName: 'super-app-theme--header',
      headerName: "Connectors",
      flex: 2,
      renderCell: (params) => (
        <>
          {connectors.map((connector) => (
            <FormControlLabel
              key={connector}
              control={
                <Checkbox
                  size="small"
                  checked={params.row.connector_mapping.includes(connector)}
                  onChange={handleCheckboxChange(params.row.id, connector)}
                />
              }
              label={connectorDisplayNames[connector]}
              sx={{ m: 0, ml: 1 }}
            />
          ))}
        </>
      ),
    }    
  ];

  return (
    <>
      <br />
      <Box style={{ height: 450, width: '98%', marginLeft: '1%' }}
      sx={{
        '& .super-app-theme--header': {
          backgroundColor: '#060694',
          color: 'white',
          fontSize: '16px'
          },
        }}
      >
      <div style={{ height: '380px', width: '100%' }}>
      {
      localStorage.getItem('userRole') === "trialuser" ?
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {/* <Button size="small" variant="contained" onClick={handleTeamsAlertClick} color="primary">Notifications</Button>&nbsp;&nbsp;&nbsp; */}
        <Button size="small" variant="outlined" onClick={handleTeamsAlertClick} color="primary">Alert Hub Settings</Button>&nbsp;&nbsp;&nbsp;
        <Button size="small" variant="outlined" onClick={handleTeamsAlertClick} color="primary">Alert Mapping</Button>&nbsp;&nbsp;&nbsp;
        <Button size="small" onClick={handleTeamsAlertClick} variant="contained" color="success">Enable All</Button>&nbsp;&nbsp;&nbsp;
        <Button size="small" onClick={handleTeamsAlertClick} variant="outlined" color="error">Disable All</Button>
      </div>
      :
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {/* <Button size="small" variant="contained" onClick={handleMonitorsClick} color="primary">Notifications</Button>&nbsp;&nbsp;&nbsp; */}
        <Button size="small" variant="outlined" onClick={() => AlertHubSetting()} color="primary">Alert Hub Settings</Button>&nbsp;&nbsp;&nbsp;
        <Button size="small" variant="outlined" onClick={handleOpen} color="primary">Alert Mapping</Button>&nbsp;&nbsp;&nbsp;
        <Modal open={open} onClose={handleClose} aria-labelledby="alert-hub-settings">
          <Box
            sx={{
              '& .super-app-theme--header': {
                backgroundColor: '#060694',
                color: 'white',
                fontSize: '16px'
              },
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
              height: '80%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" component="h2" id="alert-hub-settings">
              Alert Mapping
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: '-3%'
              }}
            >
              <Button variant="outlined" color="primary" onClick={handleUpdate}>
                Update
              </Button>
              <Button variant="contained" color="primary" onClick={handleClose} style={{ marginLeft: '1%' }}>
                Close
              </Button>
            </Box>
            <Box mt={2} sx={{ height: '90%', width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableRowSelectionOnClick={true}
              />
            </Box>
          </Box>
        </Modal>
        <Button size="small" onClick={() => UpdateAll(true)} variant="contained" color="success">Enable All</Button>&nbsp;&nbsp;&nbsp;
        <Button size="small" onClick={() => UpdateAll(false)} variant="outlined" color="error">Disable All</Button>
      </div>
      }
      <br />
        <DataGrid
        rows={monitordata}
        columns={monitorcolumns}
        pagination
        page={modalpage}
        onPageChange={handlePageChange}
        components={{
          Pagination: GridPagination,
        }}
        />
      </div>
      </Box>
      {loader}
    <Modal
      open={alertinfoopen}
      onClose={handleAlertInfoClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={viewdatastyle}>
        Alert Info <CloseIcon style={{marginLeft: '90%', cursor:'pointer'}} onClick={handleAlertInfoClose}/><hr />
        <Typography variant="body2" color="text.secondary">
          {viewalertinfodata && Object.keys(viewalertinfodata).map((key, i) => 
          {
            if(key=='data')
            {
              return <AlertInfoDataBlock alertInfoData={viewalertinfodata[key]}></AlertInfoDataBlock>
            }
          })
          }
        </Typography>
      </Box>
    </Modal>
    <Modal open={openmonitor} onClose={handleMonitorCloseModal}>
        {webhookurlexists ? 
        <Box sx={webhookstyle}>
        Update Webhook
        <CloseIcon style={{ marginLeft: '85%', cursor: 'pointer' }} onClick={handleMonitorCloseModal} />
        <hr />
        In this section, you can configure notification for user feedback from the Qlik app to be sent on your teams channel whenever any feedback / Notify-Admin request is received.
        <TextField
          style={{ width: '100%', marginTop: '20px' }}
          placeholder="Webhook URL"
          // value={webhookurlexists}
          defaultValue={webhookurlexists}
          onChange={(event) => setWebhookValue(event.target.value)}
        />
        <br />
        <br />
        <Button variant="contained" color="success" onClick={handleWebhookUpdate}>
          Update
        </Button>
        {
          webhookstatus == true ? 
          <Button variant="outlined" color="error" style={{ marginLeft: '2%'}} onClick={() => handleWebhookStatusChangeAll()}>
            Deactivate
          </Button>
          :
          <Button variant="outlined" color="success" style={{ marginLeft: '2%'}} onClick={() => handleWebhookStatusChangeAll()}>
            Activate
          </Button>
        }
        <Button variant="outlined" color="primary" style={{ marginLeft: '40%'}} onClick={SetupLink}>
          How to setup webhook channel ?
        </Button>
        </Box> : 
        <Box sx={webhookstyle}>
        Add Webhook
        <CloseIcon style={{ marginLeft: '87%', cursor: 'pointer' }} onClick={handleMonitorCloseModal} />
        <hr />
        In this section, you can configure notification for user feedback from the Qlik app to be sent on your teams channel whenever any feedback / Notify-Admin request is received.
        <TextField
          style={{ width: '100%', marginTop: '20px' }}
          placeholder="Webhook URL"
          value={webhookValue}
          onChange={(event) => setWebhookValue(event.target.value)}
        />
        <br />
        <br />
        <Button variant="contained" color="success" onClick={handleWebhookSubmit}>
          Add
        </Button>
        <Button variant="outlined" color="primary" style={{ marginLeft: '60%'}} onClick={SetupLink}>
          How to setup webhook channel ?
        </Button>
        </Box>
        }
        </Modal>
    </>
  )
}

export default AlertingConfig