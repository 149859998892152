import React, { useState, useEffect } from 'react';
import wave1 from '../../images/wave_latest.svg';
import login from '../../images/login (1).svg';
import '../../App.css';
import wowlogo from '../../images/New Wowizer Logo 1.svg';
import axios from 'axios';
import { Base_URL } from '../../constants';
import { TextField, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert';
import useFullPageLoader from '../hooks/useFullPageLoader';

const GenerateMfaIn = () => {
  const [qrCode, setQrCode] = useState('');
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [loader, showLoader, hideLoader] = useFullPageLoader();

  useEffect(() => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${Base_URL}/mfaauth/generate`,
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        if (response.data.status === "success") {
          setQrCode(response.data.qrcode);
        } else {
          console.error('Failed to generate QR code:', response.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching QR code:', error);
      });
  }, []);

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    const value = e.target.value;

    if (e.key === 'Backspace') {
        if (newOtp[index] === '') {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            if (prevInput) {
                prevInput.focus();
            }
        }
        return;
    }

    if (/^[0-9]*$/.test(value)) {
        newOtp[index] = value.slice(0, 1);
        setOtp(newOtp);

        if (value && index < otp.length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    }
  };

  const handleSubmit = () => {
    showLoader();
    const totp = otp.join('');
  
    const data = JSON.stringify({
      "totp": totp
    });
  
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: Base_URL + '/mfaauth/verify',
      headers: { 
        'Content-Type': 'application/json',
      },
      data: data
    };
  
    axios.request(config)
      .then((response) => {
        if (response.data.status === "success") {
          hideLoader();
          Swal({
            title: 'Two-Way Authentication enabled!',
            icon: 'success',
            confirmButtonText: 'OK'
        }).then(() => {
            if (window.opener) {
                window.opener.location.reload();
            }
            window.close();
        });        
        } else {
            hideLoader();
            Swal({
                title: 'Error!',
                text: 'OTP verification failed: ',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
      })
      .catch((error) => {
        hideLoader();
        Swal({
          title: error.response.data.msg,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      });
  };   

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  const isOtpComplete = otp.every(digit => digit !== '');

  return (
    <>
      <img className="wave" src={wave1} alt="wave" />
      <div className="container-login">
        <div className="img">
          <img className='login-image' src={login} alt="login visual" />
        </div>
        <div className="login-content">
          <form className='form-class-login'>
            <div className="row">
              <div className="col">
                <img className='actionate-img' src={wowlogo} width="90%" style={{ marginLeft: '-2%', marginTop: '3%' }} alt="logo" />
              </div>
            </div>
            <div className="col">
              <br />
              <div style={{ fontSize: '15px', color: '#000080' }}>
                To proceed, please enable Two-Way Authentication by scanning the QR code with Authenticator and entering the OTP.
              </div>
              {qrCode && (
                <img src={qrCode} alt="QR Code" style={{ width: '200px', height: '200px' }} />
              )}
              <br />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {otp.map((digit, index) => (
                  <TextField
                        key={index}
                        id={`otp-input-${index}`}
                        value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleOtpChange(e, index)}
                        variant="outlined"
                        inputProps={{ 
                            maxLength: 1, 
                            style: { textAlign: 'center', width: '40px', height: '40px' }, 
                            pattern: "[0-9]*",
                            inputMode: "numeric"
                        }}
                        style={{ margin: '0 5px' }}
                    />              
                ))}
              </div>
              <br />
              <div style={{ marginTop: '20px' }}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleSubmit} 
                  disabled={!isOtpComplete}
                >
                  Submit OTP
                </Button>
                {/* <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleBack}
                >
                  Back
                </Button> */}
              </div>
            </div>
          </form>
        </div>
      </div>
      {loader}
    </>
  );
};

export default GenerateMfaIn;