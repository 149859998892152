import React, { useEffect, useRef, useState } from 'react';
import { TextField, Button } from '@mui/material';
import { Email, Lock } from '@mui/icons-material';
import wave1 from '../images/wave_latest.svg';
import login from '../images/login (1).svg';
import wowlogo from '../images/New Wowizer Logo 1.svg';
import '../App.css';
import { Link, useNavigate } from 'react-router-dom';
import { Base_URL, Login_User } from '../constants';
import axios from 'axios';
import swal from 'sweetalert';
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import SendIcon from '@mui/icons-material/Send';

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const turnstileRef = useRef(null);
    const [loader, showLoader, hideLoader] = useFullPageLoader();

    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      return () => {
          document.head.removeChild(script);
      };
    }, []);

    useEffect(() => {
        window.onTurnstileSuccess = (token) => {
            setCaptchaToken(token);
        };
    }, []);

    const handleLoginSubmit = (e) => {
      showLoader();
      e.preventDefault();
  
      if (agreeToTerms) {
          var data = JSON.stringify({
              "email": email,
              "password": password,
              "captchaToken": captchaToken
          });
  
          var config = {
              method: 'post',
              url: Base_URL + Login_User,
              headers: {
                  'Content-Type': 'application/json',
              },
              data: data
          };
  
          axios(config)
              .then(function (response) {
                let config = {
                  method: 'get',
                  maxBodyLength: Infinity,
                  url: Base_URL + '/mfaauth/ready',
                  headers: { 
                    'Cookie': `access_token=${response.data.access_token}`
                  }
                };
                
                axios.request(config)
                .then((response) => {
                  const mfa_enable = response.data.mfa_enabled
                  if(mfa_enable === true){
                    hideLoader();
                    const mfa_config = response.data.mfa_configured
                    if(mfa_config === true){
                      navigate('/validateotp');
                    }else{
                      navigate('/generatemfa');
                    } 
                  }else{
                    var config = {
                      method: 'get',
                      url: Base_URL + '/users/me',
                      headers: {}
                    };
  
                    axios(config)
                      .then(function (response) {
                        hideLoader();
                          const planId = response.data.data.subscription.planId;
                          const role = response.data.data.user.role;
                          if (role === "soc_user") {
                            window.location.href = '/adminportal';
                          } else if (planId === "Standard-Realtime") {
                              window.location.href = '/analyzelogs/realtimelogs/QV00/3';
                          } else {
                              window.location.href = '/analyzelogs/realtimelogs/QV00/3';
                          }
                      })
                      .catch(function (error) {
                        hideLoader();
                          console.log(error);
                    });
                  }
                })
                .catch((error) => {
                  hideLoader();
                  console.log(error);
                });                
              })
              .catch(function (error) {
                hideLoader();
                const errorMessage = error?.response?.data?.message || "Something went wrong";
                if (errorMessage === "Invalid email or password") {
                    swal({
                        icon: 'error',
                        title: 'Login Failed',
                        text: errorMessage,
                    });
                } else if (errorMessage === "Verification failed") {
                    swal({
                        icon: 'error',
                        title: errorMessage,
                        text: "Please refresh the page as Captcha is expired",
                    }).then(() => {
                        window.location.reload();
                    });
                } else {
                    swal({
                        icon: 'error',
                        title: 'Login Failed',
                        text: "Something went wrong. Please try again",
                    }).then(() => {
                      window.location.reload();
                  });
                }
            });            
      } else {
          alert('Please agree to the Privacy Policy and License Terms & Conditions to log in.');
          hideLoader();
      }
  };  

    const navigate = useNavigate();

    const handleTrialRedirect = () => {
      navigate('/checkoutwowizer');
    };
  return (
    <>
      <img className="wave" src={wave1} />
      <div className="container-login">
        <div className="img">
          <img className='login-image' src={login} />
        </div>
        <div className="login-content">
          <form className='form-class-login' onSubmit={handleLoginSubmit}>
            <div className="row">
              <div className="col">
                <img className='actionate-img-login' src={wowlogo} width="90%" style={{ marginLeft: '-2%' }} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                <div style={{ fontSize: '20px', color: '#000080' }}>Take control of Qlik user experience and drive adoption.</div>
                <br />
                <div className="row">
                <div className="col">
                  {/* <Button variant="contained" endIcon={<SendIcon />} onClick={handleTrialRedirect}>
                    Try without Sign Up
                  </Button> */}
                </div>
                </div>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  InputProps={{
                    startAdornment: <Email />,
                  }}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  type="password"
                  fullWidth
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    startAdornment: <Lock />,
                  }}
                  required
                />
                <div
                ref={turnstileRef}
                className="cf-turnstile"
                data-sitekey="0x4AAAAAAA0EkT0QqhswxWhv"
                data-callback="onTurnstileSuccess"
                ></div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    type="checkbox"
                    checked={agreeToTerms}
                    onChange={() => setAgreeToTerms(!agreeToTerms)}
                    style={{ marginRight: '8px' }}
                  />
                  <span>
                    By logging in, you agree to the <a href='https://wowizer.ai/privacy-policy/' target="_blank">Privacy Policy</a> and <a href='https://wowizer.ai/eula/' target="_blank">License Terms & Conditions</a>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ borderRadius: '50px' }}
                  type='submit'
                >
                  Login
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                <Link to="/resetpassword" style={{ textDecoration: 'none', fontWeight: "bolder"}}>
                    Forgot Password?
                </Link>
              </div>
              {/* <div className="col">
                <br />
                <Link to="/testdrive" style={{ textDecoration: 'none', fontWeight: "bolder"}}>
                    Try without Sign Up
                </Link>
              </div> */}
              <div className="col">
                <br />
                <Link to="/register" style={{ textDecoration: 'none', fontWeight: "bolder"}}>
                    Register User
                </Link>
              </div>
            </div>
          </form>
        </div>
        {loader}
      </div>
    </>
  );
};

export default Login;
