import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';

const DataGridComponent = ({ rows, columns, loading }) => {
  return (
    <Box 
      style={{ height: 'auto', width: '98%', marginLeft: '1%', marginTop: '-2%' }}
      sx={{
        '& .super-app-theme--header': {
          backgroundColor: '#060694',
          color: 'white',
          fontSize: '19px',
          fontSize: '14px',
          whiteSpace: 'nowrap',
        },
      }}
    >
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          id="myDataGrid"
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 20]}
          pagination
          loading={loading}
          disableSelectionOnClick
          // getRowHeight={() => 75}
          initialState={{
            sorting: {
              sortModel: [{ field: 'Difference', sort: 'desc' }]
            }
          }}
        />
      </div>
    </Box>
  );
};

export default DataGridComponent;