import React, { useEffect, useState } from 'react'
import SideNav from '../components/SideNav'
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios';
import { Base_URL } from '../constants';
import { useNavigate } from "react-router-dom";
import SessionExpiredAlert from '../components/SessionExpiredAlert';
import { FcInfo } from 'react-icons/fc';
import swal from 'sweetalert';
import RealTimeSideNav from '../components/RealTimeSideNav';
import HistoricalSideNav from '../components/HistoricalSideNav';
import { Button } from '@mui/material';

const RealTimeLogs = () => {

  const [planId, setPlanId] = React.useState('');

  React.useEffect(() => {
    fetchData('');
}, []);

  const fetchData = async => {
      var config = {
          method: 'get',
          url: Base_URL + '/users/me',
          headers: { }
        };
        
        axios(config)
        .then(function (response) {
          const planId = response.data.data.subscription.planId
          setPlanId(planId)
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  const [statusData, setStatusData] = useState([]);
  const [ingestionStatus, setIngestion] = useState([]);
  const [lastSync, setLastSyc] = useState([]);
  
  useEffect(() => {
    fetchStatus();
  }, []);

  const fetchStatus = async () => {
    try {
      const response = await axios.get(Base_URL + '/users/validate_tenant/');
      setStatusData(response.data.tenant_data.server_details);
      setIngestion(response.data.tenant_data.ingestion_status);
      const dateObject = new Date(response.data.event_ts);
      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0');
      const year = dateObject.getFullYear();
      const formattedDate = `${day}/${month}/${year}, ${dateObject.toLocaleTimeString()}`;
      setLastSyc(formattedDate);
    } catch (error) {
      console.error(error);
    }    
  };

  const navigate = useNavigate();

  const handleManageRestore = () => {
    navigate('/archival');
  }

  const handleRedirect = () => {
    sessionStorage.clear();
    navigate('/analyzelogs/realtimelogs/QV00/3');

    var data = JSON.stringify({
      "dash_type": "realtime_dashboard",
      "upload_tag": "realtimelogs"
    });

    var config = {
      method: 'post',
      url: Base_URL + '/users/dashboard',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      
    })
    .catch(function (error) {
      
    });
  };

  const handleAnomalyRedirect = () => {
    navigate('/clusterdata/realtimelogs');
  };

  const tenant_name = localStorage.getItem('tenant_name');
  const currentNav = localStorage.getItem('currentNav');

  const handleRefreshClick = () => {
    fetchStatus();
  };

  const handleInfoClick = () => {
    const infoUrl = 'https://wowizer.ai/wiki/wowizer-tpm/troubleshoot/';
    window.open(infoUrl, '_blank');
  };

  const handleTrialUserClick = () => {
    swal({
      icon: 'info',
      title: 'WoWizer TPM Test Drive does not have the functionality!',
      text: "Kindly proceed with the sign up for 7 day FREE TRIAL.",
      buttons: {
        signUp: {
          text: "Complete Sign-up",
          value: "signUp",
          className: "black-text-button",
        },
        cancel: "Cancel",
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      dangerMode: true,
    })
      .then((value) => {
        if (value === "signUp") {
          const signUpPageUrl = "/register";
          window.open(signUpPageUrl, '_blank');
        }
      });
  };

  return (
    <>
    <SessionExpiredAlert />
    {currentNav === 'Real Time' ? <RealTimeSideNav /> : <HistoricalSideNav />}
    <Header />
    <div>
        <div className="content-wrapper">
          <br />
          <div className="container-fluid">
          <div className="row" style={{ display: 'flex', height: '100vh', alignItems: 'stretch' }}>
            <div className="col-md-4" style={{ marginLeft: '10%', display: 'flex', flexDirection: 'column' }}>
              <div className="card card-primary" style={{ flexGrow: 1, maxHeight: '440px', justifyContent: 'center', alignItems: 'center'  }}>
                <br /><br />
                <div className="card-body" style={{ textAlign: 'center' }}>
                  <div className="card" style={{ alignItems: 'center', backgroundColor: 'white' }}>
                    <div className="card-body">
                      <p className="card-text">
                        Experience real-time Qlik Sense integration for instant log analysis 
                      </p>
                      <Button variant='contained' href="https://wowizer.ai/wiki/wowizer-tpm/realtime-connection/" className="card-link" 
                        target="_blank"
                        style={{ backgroundColor: '#060694', fontFamily: 'Poppins, sans-serif', textTransform: 'none' }}
                      >Instructions</Button>
                    </div>
                  </div>
                  <div>
                    <br />
                    <div>
                      {planId === 'Standard-Static' ? (
                        <>
                          <div>
                            <button type="button" style={{backgroundColor: '#060694', marginLeft: '23%'}} className="btn" disabled title="Your subscription does not allow this feature">
                              <span style={{ color: 'white' }}>Realtime Dashboard</span>
                            </button>
                          </div>
                          <br /><br />
                        </>
                      ) : (
                        <>
                          {localStorage.getItem('userRole') === "trialuser" ? (
                            <div style={{ fontWeight: 'bold' }}>
                              Unique Stream ID : 
                              <span style={{ color: '#000080', fontSize: '20px'}}>
                                &nbsp;N/a
                              </span>
                            </div>
                          ) : (
                            <div style={{ fontWeight: 'bold'}}>
                              Unique Stream ID<br /> 
                              <span style={{ color: '#000080', fontSize: '20px'}}>
                                {tenant_name}
                              </span>
                            </div>
                          )}
                          <br />
                          <div>
                            {localStorage.getItem('userRole') === "trialuser" ? (
                              <button type="button" style={{backgroundColor: '#060694' }} className="btn" onClick={handleTrialUserClick}>
                                <span style={{ color: 'white' }}>Realtime Dashboard</span>
                              </button>
                            ) : (
                              <div style={{ position: 'relative' }}>
                                <button type="button" style={{backgroundColor: '#060694'}} className="btn" onClick={handleRedirect}>
                                  <span style={{ color: 'white' }}>Realtime Dashboard</span>
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div style={{ fontWeight: 'bold', marginLeft: '15%', marginTop: '15%' }}>
                    {localStorage.getItem('userRole') !== "trialuser" && (
                      <div style={{ position: 'relative', marginLeft: '10%' }}></div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6" style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="card" style={{ backgroundColor: '#072584'}}>
                <div className="card-header">
                  <h3 className="card-title" style={{ color: 'white' }}>
                    Ingestion Status: {ingestionStatus.toString().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                  </h3>
                  <br />
                  <h3 className="card-title" style={{ color: 'white', marginTop: '10px'}}>
                    <div style={{ fontSize: '15px' }}>Last Sync : {lastSync}</div>
                  </h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" onClick={handleRefreshClick}>
                      <i className="fas fa-sync-alt" style={{ color: 'white' }}></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className="card card-success" style={{ flexGrow: 1, maxHeight: '350px' }}>
                <div className="card-header" style={{ backgroundColor: '#072584' }}>
                  <h3 className="card-title">Connected Hosts ({statusData.length})</h3>
                </div>
                <div className="card-body" style={{ maxHeight: '297px', overflowY: 'auto' }}>
                  {statusData.map((node, index) => (
                    <div key={index}>
                      <h5><b><div style={{ color : '#007bff' }}>{node.hostname}</div></b></h5>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <label>QIX Performance Logging</label>
                        <div>
                          {node.details.qix_performance_service === 'running' ? (
                            <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                          ) : (
                            <>
                              <i className="fas fa-times-circle" style={{ color: 'red' }}></i>
                              <button
                                type="button"
                                className="btn btn-tool"
                                data-toggle="tooltip"
                                title="Info"
                                onClick={handleInfoClick}
                              >
                                <i className="fas fa-info-circle" style={{ fontSize: '18px', color: '#007bff' }}></i>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <label>Health Data Service</label>
                        <div>
                          {node.details.healthdata_service === 'running' ? (
                            <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                          ) : (
                            <>
                              <i className="fas fa-times-circle" style={{ color: 'red' }}></i>
                              <button
                                type="button"
                                className="btn btn-tool"
                                data-toggle="tooltip"
                                title="Info"
                                onClick={handleInfoClick}
                              >
                                <i className="fas fa-info-circle" style={{ fontSize: '18px', color: '#007bff' }}></i>
                              </button>
                            </>
                          )}
                        </div>
                      </div>

                      {node.details.qlix_services.map((service, serviceIndex) => (
                        <div key={serviceIndex} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <label>{service.log_source}</label>
                          <div>
                            {service.status === 'running' ? (
                              <i className="fas fa-check-circle" style={{ color: 'green' }}></i>
                            ) : (
                              <>
                                <i className="fas fa-times-circle" style={{ color: 'red' }}></i>
                                <button
                                  type="button"
                                  className="btn btn-tool"
                                  data-toggle="tooltip"
                                  title="Info"
                                  onClick={handleInfoClick}
                                >
                                  <i className="fas fa-info-circle" style={{ fontSize: '18px', color: '#007bff' }}></i>
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                      <hr style={{ borderColor: 'black' }} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
    </div>
    <Footer />
    </>
  )
}

export default RealTimeLogs