import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Base_URL } from '../constants';
import SessionExpiredAlert from './SessionExpiredAlert';
import HistoricalSideNav from './HistoricalSideNav';
import Header from './Header';
import Footer from './Footer';
import { Modal, Box, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SelectUploadTag = () => {
  const [uploadData, setUploadData] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [open, setOpen] = useState(true);
  const [error, setError] = useState(null);
  const tenant = localStorage.getItem('tenant_name');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUploadData = async () => {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${Base_URL}/uploadhistory/${tenant}`,
        headers: {}
      };

      try {
        const response = await axios.request(config);
        const filteredData = response.data.data.uploadhsitory.filter(upload => !upload.deleteStatus);

        if (filteredData.length === 0) {
          navigate('/analyze');
        } else {
          setUploadData(filteredData);
          const storedTag = window.localStorage.getItem("selectedUploadTag");
          if (storedTag) {
            const selectedUpload = filteredData.find(upload => upload.uploadTag === storedTag);
            if (selectedUpload) {
              const { uploadTag, qlikVersion } = selectedUpload;
              navigate(`/analyzestaticlogs/${uploadTag}/${qlikVersion}/3`);
            }
          }
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchUploadData();
  }, [tenant, navigate]);

  const handleTagChange = (event) => {
    const selectedTag = event.target.value;
    setSelectedTag(selectedTag);
  
    const selectedUpload = uploadData.find(upload => upload.uploadTag === selectedTag);
  
    if (selectedUpload) {
      const { uploadTag, qlikVersion, productType } = selectedUpload;
  
      window.localStorage.setItem("selectedUploadTag", uploadTag);
      window.localStorage.setItem("selectedProductType", productType || "unknown");
  
      navigate(`/analyzestaticlogs/${uploadTag}/${qlikVersion}/3`);
    }
  };  

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        overflowY: 'auto',
      },
    },
  };

  return (
    <>
      <SessionExpiredAlert />
      <HistoricalSideNav />
      <Header />

      <div className="content-wrapper">
        {error && <p>Error: {error}</p>}
        {!error && !uploadData.length && <p>Loading...</p>}

        {uploadData.length > 0 && !window.localStorage.getItem("selectedUploadTag") && (
          <Modal
            open={open}
            onClose={() => {}}
            disableBackdropClick
            disableEscapeKeyDown
          >
            <Box 
              sx={{
                position: 'absolute',
                top: '45%',
                left: '55%',
                transform: 'translate(-50%, -50%)',
                width: 575,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            >
              <h2>Select a Data Set (Upload Tag)</h2>
              <br />
              <FormControl fullWidth>
                <InputLabel id="upload-tag-select-label">Data Set</InputLabel>
                <Select
                  labelId="upload-tag-select-label"
                  value={selectedTag}
                  label="Data Set"
                  onChange={handleTagChange}
                  MenuProps={MenuProps}
                >
                  {uploadData.map((upload, index) => (
                    <MenuItem key={index} value={upload.uploadTag}>
                      {upload.uploadTag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <Button
                variant="outlined"
                onClick={() => navigate('/analyze')}
                sx={{ mt: 2, mr: 2 }}
              >
                Upload Data
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate('/analyze')}
                sx={{ mt: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </Modal>
        )}
      </div>

      <Footer />
    </>
  );
};

export default SelectUploadTag;